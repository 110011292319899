import React, { useContext, useCallback, useState } from 'react';
import {
  Modal, Checkbox, Button,
} from 'anf-core-react';
import {
  useMutation,
} from '@apollo/client';
import PropTypes from 'prop-types';
import BagContext from '../../../context/BagContext';
import GiftBoxMessages from './GiftBoxMessages/GiftBoxMessages';
import GIFT_BOX_UPDATE_MUTATION from '../../../gql/giftBox.gql';
import useLog from '../../useLog/useLog';
import Tmnt from '../../Tmnt/Tmnt';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import $window from '../../../tools/window';

export default function GiftBoxModal({
  openModal,
  closeModal,
  deleteGiftReceiptMutation,
}) {
  const {
    bag, setBag,
  } = useContext(BagContext);
  const tmntData = bag?.textFor;
  const priceFormatted = bag?.giftBox?.priceFormatted ? decodeHTMLEntities(bag?.giftBox?.priceFormatted) : '';
  const glbAddGiftBoxTMNTValue = `${tmntData?.glbAddGiftBoxTMNTValue?.value}${priceFormatted ? `(${priceFormatted})` : ''}`;
  const logger = useLog('shoppingBag.giftbox');
  const [selectedItem, setSelectedItem] = useState({});

  const addGiftBox = (index, value) => {
    setSelectedItem((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const [updateGiftBox] = useMutation(GIFT_BOX_UPDATE_MUTATION, {
    onCompleted: (response) => {
      logger.debug('GIFT_BOX_UPDATE_MUTATION RESULT', response.updateGiftBox);
      if (response?.updateGiftBox?.responseInfo?.success) {
        setBag((previousState) => {
          const newState = ({
            ...previousState,
            orderTotals: response?.updateGiftBox?.orderTotals,
            bagItems: response?.updateGiftBox?.bagItems,
          });

          // merge the bag response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: 'bag',
            data: newState,
            action: 'gift_box_update',
          });

          return newState;
        });
        closeModal();
      } else {
        // handle Error
        setBag((previousState) => ({
          ...previousState,
          networkResponse: response?.updateGiftBox?.responseInfo,
        }));
      }
    },
    onError: (err) => {
      logger.error('ERR: GIFT_BOX_UPDATE_MUTATION', err);
    },
  });

  const handleGiftBoxUpdate = useCallback(async (event) => {
    event.preventDefault();
    const giftBoxList = [];
    bag.bagItems.items.forEach((bagItem, bagItemIndex) => {
      if (
        selectedItem[bagItemIndex]
        || (selectedItem[bagItemIndex] === undefined && bagItem?.giftBox?.isWrapped)
      ) {
        giftBoxList.push({
          giftBoxItems: [bagItem.item.productContent.orderItemId],
          giftBoxMessage: bagItem.giftBox.giftBoxMessage,
        });
      }
    });
    await updateGiftBox({
      variables: {
        giftBoxList,
      },
    });

    if (!giftBoxList.length) {
      await deleteGiftReceiptMutation();
    }
  }, [bag, selectedItem, updateGiftBox, deleteGiftReceiptMutation]);

  return (
    <Modal
      id="gift-box-modal"
      isOpen={openModal}
      onClose={closeModal}
      closeButtonLabel="Close"
    >
      <section
        className="giftbox-image-header scope-1892"
        data-testid="gift-box-modal-content"
      >
        <span className="giftbox-bg-image" aria-label="Cardboard giftbox with large brand logo printed in dark red" role="img" />
        <div className="gift-box-data">
          <div className="data-card giftbox-modal" data-variant="boxed">
            <div className="data-card-header" />
            <div className="data-card-content">
              <h3 className="h1" data-property="GLB_GIFTBOX_DETAIL_TITLE">{tmntData?.glbGiftBoxTitleTMNTValue?.value}</h3>
              {/* Based on country we need to update price. Pull price from BagContext */}
              <div data-property="GLB_GIFTBOX_DETAILS_EXCEPTION">{`${priceFormatted} ${tmntData?.glbGiftBoxDetailsExceptionTMNTValue?.value}`}</div>
              <hr />
              <h4 data-property="GLB_DETAILS">{tmntData?.glbDetailsTMNTValue?.value}</h4>
              <Tmnt tmnt={tmntData?.glbGiftBoxDetailsExplanationTMNTValue} isHtml />
            </div>
          </div>
          <form id="giftbox-form">
            {bag?.bagItems?.items?.map((bagItem, bagItemIndex) => (
              <div key={bagItem.item.productContent.orderItemId}>
                <div className="product-template giftbox" data-brand="anf" data-alignment="horizontal">
                  <div className="product-header" />
                  <div className="product-image-section">
                    <div className="product-image">
                      <img alt={bagItem.item.image.altText} src={bagItem.item.image.imageSrc} />
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="product-content">
                      <p className="product-detail giftbox">{bagItem.item.productContent.gender}</p>
                      <div className="product-name product-name-font-size giftbox">
                        {bagItem.item.productContent.name}
                      </div>
                      <p className="product-detail giftbox">
                        {bagItem.item.productContent.color}
                        ,
                        {bagItem.item.productContent.size}
                      </p>
                      {!bagItem.item.productContent.notWrappable ? (
                        <div className="add-a-giftbox" data-testid="add-a-giftbox">
                          <Checkbox
                            id={bagItem.item.productContent.orderItemId}
                            name={bagItem.item.productContent.orderItemId}
                            value={bagItem.item.productContent.orderItemId}
                            onChange={(event) => addGiftBox(bagItemIndex, event.target.checked)}
                            isChecked={
                              selectedItem[bagItemIndex] === undefined
                                ? bagItem?.giftBox?.isWrapped : selectedItem[bagItemIndex]
                            }
                            isDefaultChecked={bagItem?.giftBox?.isWrapped}
                            description={glbAddGiftBoxTMNTValue}
                          />
                        </div>
                      ) : <p className="unavailable-for-giftbox" data-prop={tmntData?.giftboxUnavailable?.value}>{tmntData?.giftboxUnavailable?.value}</p>}
                    </div>
                  </div>
                  {(selectedItem[bagItemIndex] === undefined
                    ? bagItem?.giftBox?.isWrapped : selectedItem[bagItemIndex])
                  && (
                    <div className="product-secondary-content">
                      <GiftBoxMessages bagItem={{ ...bagItem }} bagItemIndex={bagItemIndex} />
                    </div>
                  )}
                  <div className="product-footer" />
                </div>
              </div>
            ))}
            <div className="giftbox-actions">
              <Button
                brand="anf"
                fullWidth
                variant="secondary"
                classList="gift-box-save-button"
                onClick={handleGiftBoxUpdate}
              >
                {tmntData?.chkDoneTMNTValue?.value}
              </Button>
              <Button
                brand="anf"
                fullWidth
                variant="borderless"
                classList="gift-box-cancel-button"
                onClick={() => {
                  closeModal();
                }}
              >
                {tmntData?.glbCancelTMNTValue?.value}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  );
}

GiftBoxModal.defaultProps = {
  openModal: false,
  closeModal: () => { },
  deleteGiftReceiptMutation: () => {},
};

GiftBoxModal.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteGiftReceiptMutation: PropTypes.func,
};
