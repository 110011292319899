import React from 'react';
import PropTypes from 'prop-types';

export default function KlarnaFields({ payment }) {
  return (
    <div className="klarna-fields-wrap" data-testid="klarna-fields">
      <div id="anf-klarna-iframe" />
      <input
        name="payment.klarnaPayment.paymentCode"
        value={payment?.id}
        type="hidden"
        data-testid="klarna-payment-code"
      />
    </div>
  );
}

KlarnaFields.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }).isRequired,
};
