import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import trackAction from '../../../tools/analytics';

const debounce = (func, delay) => {
  const timeoutIds = {};
  return (...args) => {
    const context = this;

    // Create a unique key for the current set of arguments
    const key = JSON.stringify(args[0]);

    // Clear previous timeout for the same arguments
    if (timeoutIds[key]) {
      clearTimeout(timeoutIds[key]);
    }

    // Set new timeout for the current arguments
    timeoutIds[key] = setTimeout(() => {
      func.apply(context, ['universal_scroll', {
        data_text: 'user scrolled to',
        data_action: args[0],
        event_type: 'scroll',
      }]);
      delete timeoutIds[key]; // Remove the timeout reference after execution
    }, delay);
  };
};

export default function RecommendationSlider({ hasItemsInBag }) {
  const [isBagMounted, setBagMounted] = useState(false);
  const [isEmptyBagMounted, setEmptyBagMounted] = useState(false);
  const sliderRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.25, // Trigger when 25% of the element is visible
    };
    let observers;
    if (hasItemsInBag && !isBagMounted) {
      setBagMounted(true);
      // tracks shopping bag mount
      trackAction('mfe-bag-mounted');
    } else if (typeof hasItemsInBag !== 'undefined' && !isEmptyBagMounted) {
      setEmptyBagMounted(true);
      // tracks empty bag mount
      trackAction('mfe-empty-bag-mounted');
    }
    const debouncedTrackAction = debounce(trackAction, 300);

    // Callback function when a slider intersects with the viewport
    const handleIntersection = (entry, index) => {
      if (entry.isIntersecting && entry.boundingClientRect.height > 0 && window.scrollY > 0) {
        // Access intersection data from the entry object
        const { target } = entry;

        // Access and print the data attributes of the element
        const dataAttributes = target.dataset;
        debouncedTrackAction(dataAttributes.sliderId);
        // Unobserve the element to prevent multiple triggers
        observers[index].unobserve(entry.target);
      }
    };

    observers = sliderRefs.current.map((sliderRef, index) => {
      const observer = new IntersectionObserver((entries) => {
        handleIntersection(entries[0], index);
      }, options);

      // Observe the slider element
      if (sliderRef) {
        observer.observe(sliderRef);
      }

      return observer;
    });

    // Clean up the observers when the component unmounts
    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, [hasItemsInBag, isBagMounted, isEmptyBagMounted]);

  // Conditional rendering with the return statement
  return (
    <div className="scope-1892" data-testid="recommendation-slider">
      {hasItemsInBag ? (
        <>
          {/* Shopping bag recommendations */}
          <div
            className="js-recommendation-slider shopping-bag-recommendations-slider"
            data-slider-id="cdp-bag-upper"
            data-placement-id="cart_page.m_rr1|cart_page.rr1"
            data-template="productSliderTemplate"
            ref={(ref) => { sliderRefs.current[0] = ref; }}
          />
          <div
            className="js-recommendation-slider shopping-bag-recommendations-slider"
            data-slider-id="cdp-bag-lower"
            data-placement-id="cart_page.m_rr2|cart_page.rr2"
            data-template="productSliderTemplate"
            ref={(ref) => { sliderRefs.current[1] = ref; }}
          />
          <div
            className="js-recommendation-slider shopping-bag-recommendations-slider"
            data-slider-id="cdp-bag-additional-suggested-products"
            data-placement-id="cart_page.m_rr3|cart_page.rr3"
            data-template="productSliderTemplate"
            ref={(ref) => { sliderRefs.current[2] = ref; }}
          />
        </>
      ) : (
        <>
          {/* Empty bag recommendations */}
          <div
            className="js-recommendation-slider shopping-bag-recommendations-slider"
            data-slider-id="cdp-bag-upper"
            data-placement-id="cart_page.m_empty1|cart_page.empty1"
            data-template="productSliderTemplate"
            ref={(ref) => { sliderRefs.current[0] = ref; }}
          />
          <div
            className="js-recommendation-slider shopping-bag-recommendations-slider"
            data-slider-id="cdp-bag-lower"
            data-placement-id="cart_page.m_empty2|cart_page.empty2"
            data-template="productSliderTemplate"
            ref={(ref) => { sliderRefs.current[1] = ref; }}
          />
          <div
            className="js-recommendation-slider shopping-bag-recommendations-slider"
            data-slider-id="cdp-bag-additional-suggested-products"
            data-placement-id="cart_page.m_empty3|cart_page.empty3"
            data-template="productSliderTemplate"
            ref={(ref) => { sliderRefs.current[2] = ref; }}
          />
        </>
      )}
    </div>
  );
}

RecommendationSlider.defaultProps = {
  hasItemsInBag: false,
};

RecommendationSlider.propTypes = {
  hasItemsInBag: PropTypes.bool,
};
