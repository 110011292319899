import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, InputField } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import CardDetails from '../CardDetails';

export default function SelectedSavedPayment({
  savedPaymentDetails,
  expTmnt,
  expiresTmnt,
  changeTmt,
  provideSecurityCodeTmnt,
  securityCodeTmnt,
  clearTmnt,
  onChange,
  onClear,
}) {
  const [cvv, setCvv] = useState('');

  const handleClear = useCallback(() => {
    onClear();
  }, [onClear]);

  return (
    <div className="selected-saved-payment">
      <div className="selected-saved-payment-card">
        <CardDetails
          iconCode={savedPaymentDetails?.iconCode}
          cardNumber={savedPaymentDetails?.maskedCardNumber}
          expiryMonth={savedPaymentDetails?.cardExpiryMonth}
          expiryYear={savedPaymentDetails?.cardExpiryYear}
          expTmnt={expTmnt}
          expiresTmnt={expiresTmnt}
        />
        <input type="hidden" name="creditCard[0].savedPaymentId" value={savedPaymentDetails?.savedPaymentId} />
        <input type="hidden" name="creditCard[0].paymentCode" value={savedPaymentDetails?.cardType} />
        <Button
          value="saved-payments"
          variant="borderless"
          classList="button ds-override"
          labelText="Change"
          onClick={() => onChange()}
        >
          <Tmnt tmnt={changeTmt} />
        </Button>
      </div>
      {savedPaymentDetails?.requiresCvv && (
        <div className="selected-saved-payment-card-security">
          <p>
            <Tmnt tmnt={provideSecurityCodeTmnt} />
          </p>
          <InputField
            label={securityCodeTmnt?.value}
            name="creditCard[0].cvv"
            id="saved-payment-card-cvv"
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
            type="tel"
            maxLength={4}
            autoComplete=""
          />
        </div>
      )}
      <div className="selected-saved-payment-card-action">
        <Button
          variant="tertiary-dark"
          labelText="Clear"
          onClick={handleClear}
        >
          <Tmnt tmnt={clearTmnt} />
        </Button>
      </div>
    </div>
  );
}

SelectedSavedPayment.defaultProps = {
  savedPaymentDetails: [],
  expTmnt: {
    key: 'REFUNDMETHOD_EXP',
    value: 'Exp:',
  },
  expiresTmnt: {
    key: 'CHK_EXPIRATION_DATE',
    value: 'Expires:',
  },
  changeTmt: {
    key: 'GLB_CHANGE',
    value: 'Change',
  },
  provideSecurityCodeTmnt: {
    key: 'CHK_ProvideCVV',
    value: 'Please confirm your security code',
  },
  securityCodeTmnt: {
    key: 'CHK_SECURITY_CODE',
    value: 'Security code',
  },
  clearTmnt: {
    key: 'GLB_CLEAR',
    value: 'Clear',
  },
};

SelectedSavedPayment.propTypes = {
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  expTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  expiresTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  changeTmt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  provideSecurityCodeTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  securityCodeTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  clearTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};
