import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import PaymentOptionsHeader from './PaymentOptionsHeader';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import PaymentTiles from './PaymentTiles/PaymentTiles';

export default function PaymentOptionsContainer({ newDesign }) {
  const [isSavedPaymentModalOpen, setIsSavedPaymentModalOpen] = useState(false);
  const [selectedSavedPayment, setSelectedSavedPayment] = useState();
  const { checkoutPageState, updateCheckoutPageState } = useContext(CheckoutPageContext);
  const paymentOptions = useMemo(
    () => checkoutPageState?.paymentOptions || [],
    [checkoutPageState?.paymentOptions],
  );
  const selectedPaymentType = checkoutPageState?.selectedPaymentType || '';
  const savedPayments = useMemo(
    () => checkoutPageState?.paymentConfig?.savedPayments || [],
    [checkoutPageState?.paymentConfig?.savedPayments],
  );
  const tmntData = checkoutPageState?.textFor;
  const isLoggedIn = checkoutPageState?.userData?.isLoggedIn;

  const onSavedPaymentModalCloseHandler = () => setIsSavedPaymentModalOpen(false);

  const getSavedPaymentById = (savedPaymentId) => {
    const savedPaymentFound = savedPayments.find(
      (savedPayment) => savedPayment?.savedPaymentId === savedPaymentId,
    );
    return savedPaymentFound;
  };

  const setSelectedPayment = useCallback((selectedPayment) => {
    updateCheckoutPageState({ selectedPaymentType: selectedPayment });
  }, [updateCheckoutPageState]);

  const onSelectHandler = (savedPaymentId) => {
    setSelectedPayment('credit card');
    const savedPaymentFound = getSavedPaymentById(savedPaymentId);
    setSelectedSavedPayment(savedPaymentFound);
    setIsSavedPaymentModalOpen(false);
  };

  const handleChangeSavedPayment = useCallback(() => {
    setIsSavedPaymentModalOpen(true);
  }, []);

  const handleClearSelectedSavedPayment = useCallback(() => {
    setSelectedSavedPayment(null);
  }, []);

  useEffect(() => {
    const seletedOption = paymentOptions?.find((paymentOption) => paymentOption?.default);
    setSelectedPayment(seletedOption?.type);
  }, [paymentOptions, setSelectedPayment]);

  return (
    <div className="payment-options">
      <PaymentOptionsHeader
        isLoggedIn={isLoggedIn}
        isModalOpen={isSavedPaymentModalOpen}
        savedPayments={savedPayments}
        tmntData={tmntData}
        onButtonClick={() => setIsSavedPaymentModalOpen(true)}
        onModalClose={onSavedPaymentModalCloseHandler}
        onSelect={onSelectHandler}
      />
      {
        newDesign
          ? (
            <PaymentOptions
              payments={paymentOptions}
              selectedPayment={selectedPaymentType}
              setSelectedPayment={setSelectedPayment}
              savedPaymentDetails={selectedSavedPayment}
              tmntData={tmntData}
              onChange={handleChangeSavedPayment}
              onClear={handleClearSelectedSavedPayment}
            />
          )
          : (
            <PaymentTiles
              payments={paymentOptions}
              selectedPayment={selectedPaymentType}
              setSelectedPayment={setSelectedPayment}
              savedPaymentDetails={selectedSavedPayment}
              tmntData={tmntData}
              onChange={handleChangeSavedPayment}
              onClear={handleClearSelectedSavedPayment}
              paymentConfig={checkoutPageState?.paymentConfig}
            />
          )
      }
    </div>
  );
}

PaymentOptionsContainer.defaultProps = {
  newDesign: true,
};

PaymentOptionsContainer.propTypes = {
  newDesign: PropTypes.bool,
};
