import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import SelectedSavedPayment from '../SavedPayments/SelectedSavedPayment';

export default function PaymentOption({
  isSelected,
  payment,
  changePaymentOption,
  savedPaymentDetails,
  tmntData,
  onChange,
  onClear,
}) {
  if (payment?.id === 'applepay' && !window.ApplePaySession) {
    return null;
  }
  return (
    <div className="payment-option">
      <RadioButton
        key={payment?.id}
        id={payment?.id}
        name="paymentCode"
        value={payment?.id}
        label={<span>{payment?.name}</span>}
        valueDescription={(
          <span className="secondary">
            <span className="payment-icon" role="img" data-icon-name={payment?.icon} />
          </span>
        )}
        isChecked={isSelected}
        isDisabled={payment?.disabled}
        onChange={() => {
          if (!payment?.disabled) {
            changePaymentOption(payment?.type);
          }
        }}
        labelDescription={(
          payment?.description && (
          <Tmnt
            tmnt={payment?.description}
            isHtml
          />
          )
        )}
      />
      {payment?.type === 'credit card' && savedPaymentDetails && (
        <SelectedSavedPayment
          savedPaymentDetails={savedPaymentDetails}
          expTmnt={tmntData?.exp}
          expiresTmnt={tmntData?.expires}
          changeTmt={tmntData?.change}
          provideSecurityCodeTmnt={tmntData?.provideSecurityCode}
          securityCodeTmnt={tmntData?.securityCode}
          clearTmnt={tmntData?.clear}
          onChange={onChange}
          onClear={onClear}
        />
      )}
    </div>
  );
}

PaymentOption.defaultProps = {
  isSelected: false,
  payment: {},
  savedPaymentDetails: null,
  tmntData: {},
  onChange: () => {},
  onClear: () => {},
};

PaymentOption.propTypes = {
  isSelected: PropTypes.bool,
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }),
  changePaymentOption: PropTypes.func.isRequired,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
