import React, {
  useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';

export default function RenderHTML({
  dataProperty,
  dataValue,
}) {
  const spanRef = useRef();

  useEffect(() => {
    spanRef.current.innerHTML = dataValue;
  }, [dataValue]);

  return (
    <span ref={spanRef} data-property={dataProperty} data-testid="inner-html" />
  );
}

RenderHTML.defaultProps = {
  dataProperty: '',
  dataValue: '',
};
RenderHTML.propTypes = {
  dataProperty: PropTypes.string,
  dataValue: PropTypes.string,
};
