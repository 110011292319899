import React, { useCallback, useState, useContext } from 'react';
import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import SwitchTestContext from '../../../context/SwitchTestContext';

export default function CheckoutButton({
  checkoutUrl,
  variant,
  tmntData,
  isDisabled,
  submitBagMutation,
  hasCheckoutButtonCartPut,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    digitalData,
  } = useContext(SwitchTestContext);

  const isSaveForLaterEnabled = digitalData && digitalData['chk-save-for-later'];

  const checkoutBtnTMNTValue = tmntData?.checkoutBtnTMNTValue?.value;
  const takingYouToCheckout = tmntData?.takingYouToCheckout?.value;

  const handleProcessing = useCallback(async () => {
    if (isDisabled) return;
    setIsProcessing(true);
    if (hasCheckoutButtonCartPut) {
      // run checkout mutation
      await submitBagMutation(
        {
          variables: {
            isSaveForLaterEnabled,
          },
        },
      );
      setIsProcessing(false);
    } else {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl, hasCheckoutButtonCartPut, isDisabled, isSaveForLaterEnabled, submitBagMutation]);
  return (
    <Button
      isFullWidth
      type="button"
      variant={variant}
      isProcessing={isProcessing}
      isDisabled={isProcessing || isDisabled}
      onClick={handleProcessing}
      classList="checkout-button"
    >
      {isProcessing ? takingYouToCheckout : checkoutBtnTMNTValue}
    </Button>
  );
}

CheckoutButton.defaultProps = {
  checkoutUrl: '',
  variant: '',
  tmntData: {},
  isDisabled: false,
  submitBagMutation: undefined,
  hasCheckoutButtonCartPut: false,
};

CheckoutButton.propTypes = {
  checkoutUrl: PropTypes.string,
  variant: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  isDisabled: PropTypes.bool,
  submitBagMutation: PropTypes.func,
  hasCheckoutButtonCartPut: PropTypes.bool,
};
