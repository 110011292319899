import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RecommendationsContext, useUserConsent } from '@xp-utilities/web';

const propTypes = { children: PropTypes.node.isRequired, id: PropTypes.string.isRequired };

export const generateRecommendationsCallEventName = (id) => `recommendations-${id}`;

export default function MiniBagRecommendationsContextProvider({ id, children }) {
  const { getUserConsent } = useUserConsent();
  const [miniBagData, setMiniBagData] = useState(null);

  const ctx = useMemo(
    () => ({
      id,
      isEventDriven: true,
      variables: {
        hasUserConsent: getUserConsent(),
        products: miniBagData || [],
      },
    }),
    [id, getUserConsent, miniBagData],
  );

  useEffect(() => {
    window?.digitalData?.subscribe('cart.data.bagItems', (data) => {
      const items = data?.items;
      const miniBagItems = items?.map((item) => ({ kic: item.item.image?.imageId }));

      setMiniBagData(miniBagItems);
    });
  }, []);

  useEffect(() => {
    if (!miniBagData) return;
    window?.digitalData?.trigger(generateRecommendationsCallEventName(id));
  }, [miniBagData, id]);

  return (
    <RecommendationsContext.Provider value={ctx}>
      { children }
    </RecommendationsContext.Provider>
  );
}

MiniBagRecommendationsContextProvider.propTypes = propTypes;
