import React from 'react';
import PropTypes from 'prop-types';
import { Recommendations } from '@xp-utilities/web';
import MiniBagRecommendationsContextProvider from '../../context/MiniBagRecommendations/MiniBagRecommendationsContextProvider';

export const INSTANCE_DATA_ATTRIBUTE = 'data-recommendations-client';

export default function MiniBagInstrumentedRecommendations({ clientId, placements }) {
  return (
    <MiniBagRecommendationsContextProvider id={clientId}>
      <div
        id={clientId}
        data-testid={clientId}
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ [INSTANCE_DATA_ATTRIBUTE]: clientId }}
      >
        <Recommendations placements={placements} />
      </div>
    </MiniBagRecommendationsContextProvider>
  );
}

MiniBagInstrumentedRecommendations.propTypes = {
  clientId: PropTypes.string.isRequired,
  placements: PropTypes.arrayOf(PropTypes.string),
};
MiniBagInstrumentedRecommendations.defaultProps = { placements: [] };
