import React from 'react';
import {
  gql, useQuery,
} from '@apollo/client';
import { Button, IconBlock } from 'anf-core-react';
import MarketingSpot from '../../Common/MarketingSpot/MarketingSpot';
import {
  ERROR_MESSAGE as errorMessage,
  LOADING_MESSAGE as loadingMessage,
} from '../../Common/Messages/Messages';

const emptyBagTMNTQuery = gql`
  {
    textFor {
      signInButton: pair (pairKey: "emptyBagSignInButton") {...values}
      emptyBagIconText: pair (pairKey: "shoppingBagEmptyText") {...values}
      itemsInYourBagText: pair (pairKey: "itemsInYourBagText") {...values}
    }
  }

  fragment values on TextPair {
    key
    value
  }
`;

export default function EmptyBag() {
  const { loading, error: queryError, data: queryData } = useQuery(emptyBagTMNTQuery);
  if (loading) return loadingMessage;
  if (queryError) return errorMessage;

  const {
    signInButton,
    emptyBagIconText,
    itemsInYourBagText,
  } = queryData.textFor;

  const showSignInButton = () => (
    <Button
      variant="secondary"
      classList="emptybag-signin-button login-trigger"
    >
      <span>{signInButton.value}</span>
    </Button>
  );

  return (
    <div className="checkout-empty-bag">
      <IconBlock icon="bag" alignIcon="center" orientation="vertical">
        {emptyBagIconText.value}
      </IconBlock>
      <div className="emptybag-signin__content">
        <h4 className="emptybag-signin-header-text h4">{itemsInYourBagText.value}</h4>
        {showSignInButton()}
        <div className="emptybag-shopbuttons__content">
          <MarketingSpot espotId="EmptyBagEspot-ShopButtons" />
        </div>
      </div>
    </div>
  );
}
