import React from 'react';
import PropTypes from 'prop-types';

export default function PaymentTile({
  isSelected,
  payment,
  changePaymentOption,
}) {
  if (payment?.id === 'applepay' && !window.ApplePaySession) {
    return null;
  }
  return (
    <div className="payment-option">
      <div
        className={`radio-button js-radio-button ${payment?.id}`}
        key={payment?.id}
      >
        <input
          id={payment?.id}
          type="radio"
          value="0"
          checked={isSelected}
          data-payment-code={payment?.type}
          data-payment-type={payment?.type}
          data-type-name={payment?.name}
          name="payment-tile-group-0"
          onChange={() => {
            if (!payment?.disabled) {
              changePaymentOption(payment?.type);
            }
          }}
          disabled={payment?.disabled}
        />
        <label htmlFor={payment?.id}>
          <span className="custom-radio">
            <span className="radio-button-interior-label">
              <span>{payment?.name}</span>
              <span className="secondary">
                <span
                  className="payment-icon"
                  role="img"
                  data-icon-name={payment?.icon}
                  aria-hidden="true"
                />
              </span>
            </span>
          </span>
        </label>
      </div>
    </div>
  );
}

PaymentTile.defaultProps = {
  isSelected: false,
  payment: {},
};

PaymentTile.propTypes = {
  isSelected: PropTypes.bool,
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }),
  changePaymentOption: PropTypes.func.isRequired,
};
