import React, {
  createRef, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  Button, ProductCard, Badge, ProductImage, ProductDetail,
  ProductName, ProductPrice, IconButton, ErrorMessage,
} from 'anf-core-react';
import ProductTemplate from '../product-template/ProductTemplate';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import { useIsXS } from '../../../hooks/useBreakPoint';

export default function BagList({
  isSaveForLaterEnabled,
  variant,
  bagItems,
  removeBagItem,
  tmntData,
  showShopSimilarItems,
  moveToSFL,
  moveToBag,
  deleteFromSFL,
  shippingInfo,
}) {
  const [transitionClass, setTransitionClass] = useState('fade');
  const isXS = useIsXS();
  const emptyBagText = tmntData?.emptyBagText?.value;
  const moveToBagText = tmntData?.moveToBagButtonValue?.value || 'Move to Bag';
  const closeSflLabelTextValue = tmntData?.closeSFLBtn?.value || 'Delete SFL Button';
  let nodeRef = null;
  const handleMoveToBag = useCallback((event, listItemId, sku, index) => {
    event.preventDefault();
    setTransitionClass('fade');
    moveToBag(listItemId, sku, index);
  }, [moveToBag]);

  const handleRemoveFromSFL = useCallback((event, listItemId, index) => {
    event.preventDefault();
    setTransitionClass('slide-left');
    deleteFromSFL(listItemId, index);
  }, [deleteFromSFL]);

  const handleRemoveBagItem = (data) => {
    setTransitionClass('slide-left');
    removeBagItem(data);
  };

  const handleMoveToSFL = (orderItemId, sku, index) => {
    setTransitionClass('fade');
    moveToSFL(orderItemId, sku, index);
  };

  const hasPreOrder = useCallback(() => !!bagItems?.find((items) => items?.item?.headers?.badgeStatusMessage.key === 'BADGE_STATUS_MESSAGE_PRE_ORDER'), [bagItems]);

  const buildBagList = () => {
    if (!Array.isArray(bagItems) || (bagItems && bagItems?.length) === 0) {
      nodeRef = createRef(null);
      return (
        <CSSTransition
          nodeRef={nodeRef}
          timeout={500}
          classNames="fade"
        >
          <div ref={nodeRef} className="empty-bag">{emptyBagText}</div>
        </CSSTransition>
      );
    }
    return bagItems.map((bagItem, i) => {
      nodeRef = createRef(null);

      const discountTxtVal = bagItem?.item?.productContent?.productPrice?.discountText;
      const discountTxt = tmntData?.[discountTxtVal]?.value
        ? tmntData?.[discountTxtVal]?.value : discountTxtVal;

      const bagListError = bagItem?.item?.displayError;
      const key = `${bagItem?.item?.productContent.shortSku}-${i}`;
      const originalPrice = bagItem?.item?.productContent?.productPrice?.originalPrice ? decodeHTMLEntities(bagItem?.item?.productContent?.productPrice?.originalPrice) : '';
      const discountPrice = bagItem?.item?.productContent?.productPrice?.discountPrice ? decodeHTMLEntities(bagItem?.item?.productContent?.productPrice?.discountPrice) : '';
      return (
        <CSSTransition
          key={key}
          nodeRef={nodeRef}
          timeout={500}
          classNames={transitionClass}
        >
          <li ref={nodeRef} className="product-template-item">
            {variant === 'sfl' ? (
              <>
                <ProductCard
                  badge={bagItem?.item?.headers?.shouldDisplayAsbadge ? (
                    <Badge isAttached status={bagItem?.item?.headers?.variant}>
                      {bagItem?.item?.headers?.badgeStatusMessage.value}
                    </Badge>
                  ) : null}
                  closeButton={<IconButton icon="close" labelText={closeSflLabelTextValue} theme="inverse" variant="square" onClick={(evt) => { handleRemoveFromSFL(evt, bagItem?.item?.productContent?.listItemId, i); }} />}
                  header={<ProductDetail>{`${bagItem?.item?.productContent.gender} #${bagItem?.item?.productContent?.shortSku}`}</ProductDetail>}
                  productImage={(

                    <ProductImage
                      alt={bagItem?.item?.image?.altText}
                      data-itemid={bagItem?.item?.productContent?.orderItemId}
                      size={isXS ? 'm' : 'l'}
                      src={bagItem?.item?.image?.imageSrc}
                      classList="js-pc-edit-button"
                    />
        )}
                  productName={(
                    <ProductName>
                      <h2>{decodeHTMLEntities(bagItem?.item?.productContent?.name)}</h2>
                    </ProductName>
                  )}
                  productPrice={(
                    <ProductPrice
                      brand={bagItem?.item?.productContent?.brand}
                      description={bagItem?.item?.productContent?.productPrice?.description}
                      originalPrice={originalPrice}
                      discountPrice={discountPrice}
                      discountText={discountTxt}
                      variant={bagItem?.item?.productContent?.productPrice?.variant}
                    />
)}
                >
                  <div className="product-detail-container">
                    <ProductDetail>
                      <p className="gender">
                        {bagItem?.item?.productContent.gender && variant !== 'mini' ? `${bagItem?.item?.productContent.gender}: ` : bagItem?.item?.productContent.gender}
                        {!bagItem?.item?.productContent.gender && `${tmntData?.skuText?.value}: `}
                        {variant !== 'mini' && bagItem?.item?.productContent.shortSku && bagItem?.item?.productContent?.shortSku}
                      </p>
                      <p className="size-color">
                        {`${bagItem?.item?.productContent.size}${bagItem?.item?.productContent.size && bagItem?.item?.productContent.color ? ', ' : ''} ${bagItem?.item?.productContent.color}`}
                      </p>
                      <div className="product-price-section">
                        <ProductPrice
                          brand={bagItem?.item?.productContent?.brand}
                          description={bagItem?.item?.productContent?.productPrice?.description}
                          originalPrice={originalPrice}
                          discountPrice={discountPrice}
                          discountText={discountTxt}
                          variant={bagItem?.item?.productContent?.productPrice?.variant}
                        />
                      </div>
                      {bagItem?.item?.headers?.estimatedShipDate && <div className="pre-order-shipment-date" data-testid="pre-order-date">{bagItem?.item?.headers?.estimatedShipDate}</div>}
                    </ProductDetail>
                    {bagItem?.item?.headers?.badgeStatusMessage.key !== 'CAT_SOLDOUT' && (
                      <div className="product-card-ctas">
                        <ul>
                          <li>
                            <Button
                              classList="link-button move-to-bag"
                              variant="borderless"
                              onClick={(evt) => handleMoveToBag(
                                evt,
                                bagItem?.item?.productContent?.listItemId,
                                bagItem?.item?.productContent?.shortSku,
                                i,
                              )}
                              isProcessing={bagItem?.item?.isProcessing}
                              isDisabled={bagItem?.item?.isProcessing}
                            >
                              {moveToBagText}
                            </Button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </ProductCard>
                {bagListError && <div className="error-section" data-testid="sfl-generic-error"><ErrorMessage>{bagListError }</ErrorMessage></div>}
              </>
            ) : (
              <>
                <ProductTemplate
                  variant={variant}
                  bagItem={bagItem.item}
                  currentIndex={i}
                  hasGiftBox={bagItem?.giftBox?.isWrapped}
                  removeBagItem={handleRemoveBagItem}
                  tmntData={tmntData}
                  showShopSimilarItems={showShopSimilarItems}
                  isSaveForLaterEnabled={isSaveForLaterEnabled}
                  moveToSFL={handleMoveToSFL}
                  shippingInfo={shippingInfo}
                  hasPreOrder={hasPreOrder()}
                />
                {bagListError && <div className="error-section" data-testid="move-to-sfl-error"><ErrorMessage>{bagListError}</ErrorMessage></div>}
              </>
            ) }
          </li>
        </CSSTransition>
      );
    });
  };

  return (
    <ul className="shopping-bag-list-mfe product-list-height scope-1892">
      <TransitionGroup component={null}>
        {/* list of product item */}
        {buildBagList()}
      </TransitionGroup>
    </ul>
  );
}

BagList.defaultProps = {
  isSaveForLaterEnabled: false,
  variant: null,
  bagItems: [],
  removeBagItem: undefined,
  tmntData: {},
  showShopSimilarItems: false,
  moveToSFL: undefined,
  moveToBag: undefined,
  deleteFromSFL: undefined,
  shippingInfo: {},
};

BagList.propTypes = {
  isSaveForLaterEnabled: PropTypes.bool,
  variant: PropTypes.string,
  bagItems: PropTypes.instanceOf(Array),
  removeBagItem: PropTypes.func,
  tmntData: PropTypes.instanceOf(Object),
  showShopSimilarItems: PropTypes.bool,
  moveToSFL: PropTypes.func,
  moveToBag: PropTypes.func,
  deleteFromSFL: PropTypes.func,
  shippingInfo: PropTypes.instanceOf(Object),
};
