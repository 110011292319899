import React, { useContext } from 'react';
import { Checkbox } from 'anf-core-react';
import {
  useMutation,
} from '@apollo/client';
import PropTypes from 'prop-types';
import BagContext from '../../../context/BagContext';
import useLog from '../../useLog/useLog';
import GIFT_RECEIPT_UPDATE_MUTATION from '../../../gql/giftReceipt.gql';
import trackAction from '../../../tools/analytics';
import $window from '../../../tools/window';

export default function GiftReceipt({ deleteGiftReceiptMutation }) {
  const {
    bag, setBag,
  } = useContext(BagContext);
  const logger = useLog('shoppingBag.giftreceipt');
  const tmntData = bag?.textFor;
  const addFreeGiftReceiptTMNTValue = tmntData?.addFreeGiftReceiptTMNTValue?.value;
  const giftReceiptIncluded = tmntData?.giftReceiptIncluded?.value;

  const [updateGiftReceiptMutation] = useMutation(GIFT_RECEIPT_UPDATE_MUTATION, {
    onCompleted: (updateGiftReceiptObj) => {
      logger.debug('GIFT_RECEIPT_UPDATE_MUTATION RESULT', updateGiftReceiptObj.updateGiftReceipt);
      if (updateGiftReceiptObj?.updateGiftReceipt?.responseInfo?.success) {
        setBag((previousState) => {
          const newState = ({
            ...previousState,
            orderTotals: updateGiftReceiptObj?.updateGiftReceipt?.orderTotals,
          });

          // merge the bag response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: 'bag',
            data: newState,
            action: 'gift_receipt_update',
          });

          return newState;
        });
      } else {
        // handle Error
        setBag((previousState) => ({
          ...previousState,
          networkResponse: updateGiftReceiptObj?.updateGiftReceipt?.responseInfo,
        }));
      }
    },
    onError: (err) => {
      logger.error('ERR: GIFT_RECEIPT_UPDATE_MUTATION', err);
    },
  });

  const updateGiftReceipt = (event) => {
    if (event?.target?.checked) {
      trackAction('universal_click', {
        data_text: 'gift receipt',
        data_action: 'add',
        event_type: 'click',
      });
      updateGiftReceiptMutation();
    } else {
      deleteGiftReceiptMutation();
    }
  };

  return (
    <div className="shopping-bag-gift-receipt" data-testid="gift-receipt">
      <Checkbox
        id="giftReceiptCheckbox"
        name="giftReceiptCheckbox"
        data-testid="giftReceiptCheckbox"
        onChange={(event) => updateGiftReceipt(event)}
        isDefaultChecked={bag?.orderTotals?.isGiftReceiptChecked}
        isChecked={bag?.orderTotals?.isGiftReceiptChecked}
        value={addFreeGiftReceiptTMNTValue}
        description={!!bag?.orderTotals?.totalGiftBoxes
          && bag?.orderTotals?.isGiftReceiptChecked
          ? giftReceiptIncluded : addFreeGiftReceiptTMNTValue}
        isDisabled={bag?.bagItems?.hasOnlyGiftCard}
      />
    </div>
  );
}

GiftReceipt.defaultProps = {
  deleteGiftReceiptMutation: () => {},
};

GiftReceipt.propTypes = {
  deleteGiftReceiptMutation: PropTypes.func,
};
