import {
  gql,
} from '@apollo/client';

const PAY_OVER_TIME_QUERY = gql`
  query($purchaseAmount: Float, $page: String, $hasGiftCard: Boolean) {
    textFor {
      payOverTimeMessagingHeading: pair(pairKey: "payOverTimeMessagingHeading") {...values}
      payOverTimeMessagingLearnMore: pair (pairKey: "payOverTimeMessagingLearnMore") {...values}
      glbOr: pair(pairKey: "glbOr") {...values}
    }
    messagePlacement {
      payOverTime(purchaseAmount: $purchaseAmount, page: $page, hasGiftCard: $hasGiftCard) {
        enabled,
        qualified,
        klarna {
          enabled,
          badgeURL,
          badgeAlt,
          learnMoreURL,
          impressionURL
        }
        payPal {
          enabled,
          badgeURL,
          badgeAlt,
          clientSDK,
          offer
        }
        installmentMessage
        purchaseAmount
        page
      }
    }
  }
  fragment values on TextPair {
    key
    value
  }
`;

export default PAY_OVER_TIME_QUERY;
