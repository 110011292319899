import {
  gql,
} from '@apollo/client';

const OIS_START_NEW_SESSION_MUTATION = gql`
  mutation StartOISNewSession {
    startOISNewSession {
      responseInfo {
        statusCode
        success
        statusMessages {
          code
          key
          message
        }
      }
    }
  }
`;

const OIS_SEND_TO_REGISTER = gql`
  mutation SendToRegister(
    $email: String!,
    $brand: String!,
    $oisTrackingId: String!,
    $skus: String!,
    $store: String!,
    $sendToRegisterURL: String!

  ) {
    sendToRegister(
      email: $email
      brand: $brand
      oisTrackingId: $oisTrackingId
      skus: $skus
      store: $store
      sendToRegisterURL: $sendToRegisterURL
    ) {
      responseInfo {
        statusCode
        success
        statusMessages {
          code
          key
          message
        }
      }
    }
  }
`;

const OIS_SEND_TO_CUSTOMER = gql`
  mutation SendToCustomer(
    $email: String!,
    $optIn: Boolean!,
  ) {
    sendToCustomer(
      email: $email
      optIn: $optIn
    ) {
      responseInfo {
        statusCode
        success
        statusMessages {
          code
          key
          message
        }
      }
    }
  }
`;

export {
  OIS_START_NEW_SESSION_MUTATION,
  OIS_SEND_TO_REGISTER,
  OIS_SEND_TO_CUSTOMER,
};
