import React from 'react';
import PropTypes from 'prop-types';

function renderShimmerLoader(contentRows) {
  const loaderRows = [];
  for (let row = 1; row <= contentRows; row += 1) {
    loaderRows.push(
      <div className="left-rail-row" key={`shimmer-loader-row-${row}`}>
        <div className="image" />
        <div className="content" />
      </div>,
    );
  }

  return (
    <div className="shimmer-loader">
      <div className="left-rail">
        <div className="first-row" />
        <div className="second-row" />
        {loaderRows}
      </div>
      <div className="payment-rail" />
    </div>
  );
}

function renderLoader(rows) {
  const loaderRows = [];
  for (let row = 1; row <= rows; row += 1) {
    loaderRows.push(
      <span key={`loader-row-${row}`} />,
    );
  }

  return loaderRows;
}

export default function Loader({
  variant,
  classList,
  contentRows,
  children,
  isActive,
  id,
}) {
  let innerContent;
  if (variant === 'dots') {
    innerContent = renderLoader(2);
  } else if (variant === 'spinner') {
    innerContent = renderLoader(6);
  } else if (variant === 'shimmer') {
    return renderShimmerLoader(contentRows);
  }

  const loaderContent = variant === 'hazy' ? (
    <span data-testid={id} className={`hazy-loader ${classList}`} data-variant={variant} data-state={isActive}>
      <span className="screen-reader-text">loading...</span>
      {children}
    </span>
  ) : (
    <span className={`loader ${classList}`} data-variant={variant}>
      {innerContent}
      <span className="screen-reader-text">loading...</span>
    </span>
  );
  return loaderContent;
}

Loader.defaultProps = {
  variant: 'spinner',
  classList: '',
  contentRows: 1,
  children: '',
  isActive: false,
  id: '',
};

Loader.propTypes = {
  variant: PropTypes.oneOf(['spinner', 'hazy', 'dots', 'shimmer']),
  classList: PropTypes.string,
  contentRows: PropTypes.number,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  id: PropTypes.string,
};
