import React from 'react';
import PropTypes from 'prop-types';
import PaymentTile from './PaymentTile';
import PaymentFields from '../PaymentFields/PaymentFields';
// import { PaymentBlock } from 'anf-core-react';

export default function PaymentTiles({
  payments,
  selectedPayment,
  setSelectedPayment,
  savedPaymentDetails,
  paymentConfig,
  tmntData,
  onChange,
  onClear,
}) {
  const selectedPaymentObject = payments?.find((payment) => payment?.type === selectedPayment);

  return (
    <div className="payment-options">
      <div aria-label="" className="payment-tile-group" role="radiogroup">
        {
          payments?.map((payment) => (
            <PaymentTile
              key={payment?.id}
              isSelected={selectedPayment === payment?.type}
              payment={payment}
              changePaymentOption={setSelectedPayment}
              savedPaymentDetails={savedPaymentDetails}
              tmntData={tmntData}
              onChange={onChange}
              onClear={onClear}
            />
          ))
        }
      </div>
      {selectedPaymentObject && (
        <PaymentFields
          selectedPayment={selectedPaymentObject}
          paymentConfig={paymentConfig}
          tmntData={tmntData}
          savedPaymentDetails={savedPaymentDetails}
          setSelectedPayment={setSelectedPayment}
          onChange={onChange}
          onClear={onClear}
        />
      )}
    </div>
  );
}

PaymentTiles.defaultProps = {
  payments: [],
  selectedPayment: '',
  savedPaymentDetails: null,
  paymentConfig: {},
  tmntData: {},
  setSelectedPayment: () => {},
  onChange: () => {},
  onClear: () => {},
};

PaymentTiles.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  })),
  selectedPayment: PropTypes.string,
  setSelectedPayment: PropTypes.func,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  paymentConfig: PropTypes.instanceOf(Object),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
