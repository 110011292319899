import React, { useContext } from 'react';
import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SwitchTestContext from '../../../context/SwitchTestContext';

export default function ApplePayButton({
  orderConfirmationURL,
  classList,
  tmntData,
  isDisabled,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const applePayBtnTMNTValue = tmntData?.applePayButtonTMNTValue?.value;
  const hasApplePayPXP = digitalData && digitalData['chk-has-apple-pay-pxp'];
  // CRS is looking for the class apple-pay-checkout to handle the click event
  const requiredlClassList = hasApplePayPXP ? 'apple-pay' : 'apple-pay apple-pay-checkout';

  return (
    <Button
      isFullWidth
      href={(isDisabled || hasApplePayPXP) ? null : orderConfirmationURL}
      classList={clsx(classList, requiredlClassList)}
      // NOTE: checkoutApplePayRequest.js has a live listener for click events on this classname
      // apple-pay-checkout, so the click event from this button is
      // being handled by phoenix / crs-app
      onClick={() => {
        if (!hasApplePayPXP) {
          window.dispatchEvent(new Event('applepay:display'));
        } else if (typeof onMerchantButtonClick === 'function') {
          // the function name will change once PXP updates the code
          // it's defined within applepay.js
          /* eslint-disable-next-line no-undef */
          onMerchantButtonClick();
        }
      }}
      isDisabled={isDisabled}
    >
      <span role="img" aria-label={applePayBtnTMNTValue} />
    </Button>
  );
}

ApplePayButton.defaultProps = {
  tmntData: {},
  orderConfirmationURL: '',
  classList: '',
  isDisabled: false,
};

ApplePayButton.propTypes = {
  orderConfirmationURL: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  classList: PropTypes.string,
  isDisabled: PropTypes.bool,
};
