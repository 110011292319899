/**
 * getDigitalDataSubscriptionPromise function takes keyName and subscribes to that key name in
 * dataBridge in `test` object.
 * 1. Will wait for subscription to trigger the keySubscriptionHandler value until waitTime
 * 2. If value is found with subscription resolve the promise with that value
 * 3. If value NOT found and timeout is hit then resolve with value found for the keyName in `flag`
 * 4. If no value found for keyName in either `test` or `flag` resolve to false
 *
 * @param {*} keyName
 * @param {*} waitTime
 * @returns promise
 */
function getDigitalDataSubscriptionPromise(keyName, waitTime = 0) {
  let timeoutId = null;
  let resolver;
  const thePromise = new Promise((res) => {
    resolver = res;
  });

  const featureFlagKey = `flag.${keyName}`;
  const splitTestKey = `test.${keyName}`;

  function keySubscriptionHandler(value) {
    if (typeof value !== 'undefined') {
      // handler cleans up subscriptions, clears time out and resolves with a valid value
      this.unsubscribe();
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      resolver(value);
    }
  }
  // subscribe to split test key as it may change after a while
  const subscriptionSplitTest = window.digitalData.subscribe(splitTestKey, keySubscriptionHandler);

  timeoutId = setTimeout(() => {
    subscriptionSplitTest.unsubscribe();
    const featureFlagValue = window.digitalData.get(featureFlagKey, undefined);
    resolver(typeof featureFlagValue !== 'undefined' ? featureFlagValue : false);
  }, waitTime);

  return thePromise;
}

module.exports = { getDigitalDataSubscriptionPromise };
