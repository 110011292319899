import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import useLog from '../../useLog/useLog';

// Higher Order Component to wrap a component in a portal
const CreatePortal = (WrappedComponent, defaultId) => {
  function PortalComponent({ id, ...props }) {
    const [portalNode, setPortalNode] = useState(null);
    const logger = useLog(`portal.${id}`);

    useEffect(() => {
      const nodeId = id || defaultId;
      const node = document.getElementById(nodeId);
      if (node) {
        setPortalNode(node);
      } else {
        logger.error(`Portal node with ID ${nodeId} not found.`);
      }
    }, [id, logger]);

    if (portalNode) {
      return ReactDOM.createPortal(<WrappedComponent {...props} />, portalNode);
    }
    return null;
  }

  PortalComponent.propTypes = {
    id: PropTypes.string,
  };

  PortalComponent.defaultProps = {
    id: defaultId,
  };

  return PortalComponent;
};

export default CreatePortal;
