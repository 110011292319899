import { getShippingAddress, getBillingAddress } from '../../../tools/address';

export default function prepareFormData(formData, checkoutPageState = {}) {
  const { shippingSpeed } = checkoutPageState;
  return {
    billingAddressSameAsShipping: formData?.billingAddressSameAsShipping ?? false,
    shippingAddressSameAsBilling: formData?.shippingAddressSameAsBilling ?? false,
    estimatedDeliveryDate: formData?.estimatedDeliveryDate ?? '',
    estimatedShipDate: formData?.estimatedShipDate ?? '',
    items: formData?.items ?? [],
    payment: formData?.payment ?? {},
    shippingAddress: formData?.shippingAddress ?? getShippingAddress(formData, shippingSpeed),
    billingAddress: formData?.billingAddress ?? getBillingAddress(formData, shippingSpeed),
    shipModeId: formData?.shipModeId ?? '',
    signifydSessionId: formData?.signifydSessionId ?? '',
    smsOptIn: formData?.smsOptIn ?? false,
    valueTierFlag: formData?.valueTierFlag ?? '',
    marketingInfo: formData?.marketingInfo ?? {},
  };
}
