import React, {
  useContext, useState, useEffect,
} from 'react';
import { Button } from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';

export default function Survey() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { survey, textFor: tmntData } = orderConfirmationData;
  const surveyUrl = survey?.surveyLinkUrl;

  const [qmInfo, setQmInfo] = useState(
    { sessionId: '', userId: '' },
  );

  useEffect(() => {
    const startEventListener = () => {
      window.QuantumMetricAPI.addEventListener('start', () => {
        const sessionId = window?.QuantumMetricAPI?.getSessionID();
        const userId = window?.QuantumMetricAPI?.getUserID();

        if (sessionId) {
          setQmInfo({ sessionId, userId });
        }
      });
    };

    if (!window.QuantumMetricAPI) {
      const onLoad = (window.QuantumMetricOnload instanceof Array)
        ? window.QuantumMetricOnload : [];
      if (typeof window.QuantumMetricOnload === 'function') {
        onLoad.push(window.QuantumMetricOnload);
      }
      onLoad.push(startEventListener);
      window.QuantumMetricOnload = onLoad;
    } else {
      startEventListener();
    }
  }, []);

  const userID = qmInfo.userId;
  const sessionID = qmInfo.sessionId;
  const link = `${surveyUrl}&QMUserID=${encodeURIComponent(userID)}&QMSessionID=${encodeURIComponent(sessionID)}`;

  return (
    <section className="order-review-survey upper" data-testid="survey-wrapper">
      <h2 className="survey-heading">
        {tmntData?.surveyHeading?.value}
      </h2>
      <p className="survey-description">
        {tmntData?.surveyDesc?.value}
      </p>

      <Button
        isFullWidth
        type="submit"
        href={link}
        classList="link-as-tertiary-dark-button survey-submit-btn"
        data-testid="survey-link"
      >
        {tmntData?.surveyLinkLabel?.value}
      </Button>
    </section>
  );
}

Survey.defaultProps = {
  surveyUrl: '',
  tmntData: {},
};

Survey.propTypes = {
};
