const MINI_BAG_SWITCHES = [
  'chk-mfe-miniBag-mobile',
  'chk-mini-bag-toaster-delay',
  'chk-temp-mini-bag-scroll',
];

const BAG_SWITCHES = [
  'chk-save-for-later',
  'chk-ois-start-session-with-fetch',
  'chk-bag-repudiations-update', // temporary switch for checkout button
  'chk-has-apple-pay-pxp',
];

const CHECKOUT_SWITCHES = [
  'chk-addr-verify-enabled',
  'chk-order-contact-info-mfe-enabled',
  'chk-addr-verify-accept-threshold',
  'chk-google-pay-pxp-script-url',
  'chk-google-pay-config',
  'chk-use-mfe-submit-button',
  'ful-sms-opt-in',
  'chk-hco-only-marketing-checkboxes',
  'ful-delivery-options-tabs',
  'ful-delivery-options-popins-modal',
  'ful-delivery-options-pudos-modal',
  'check-pay-pal-express-parameters-in-url',
  'ful-delivery-options-title',
  'chk-submit-overlay',
  'chk-use-subscribe-actions',
  'chk-has-apple-pay-pxp',
  'chk-use-payment-section-mfe',
  'chk-use-state-as-country-googlepay',
  'chk-legal-submit-order-copy',
];
const OCN_PAGE_SWITCHES = [
  'chk-is-ocn-mfe-enabled',
  'chk-use-mfe-marketing-subscribe',
];

module.exports = {
  MINI_BAG_SWITCHES,
  BAG_SWITCHES,
  CHECKOUT_SWITCHES,
  OCN_PAGE_SWITCHES,
};
