/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useContext,
} from 'react';
import {
  useMutation,
} from '@apollo/client';
import Loader from '../../Common/Loader/Loader';
import { SHIPPING_SPEED_UPDATE_MUTATION } from '../../../gql/shippingSpeed.gql';
import ShippingSpeedOptions from './ShippingSpeedOptions';
import { ERROR_MESSAGE as ErrorMessage } from '../../Common/Messages/Messages';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import useLog from '../../useLog/useLog';
import $window from '../../../tools/window';

export default function ShippingSpeed() {
  const {
    updateCheckoutPageState,
    error,
    refetch: fetchShippingSpeed,
    checkoutPageState,
    loading,
    setIsUpdating,
    isUpdating,
  } = useContext(CheckoutPageContext);
  const logger = useLog('checkout.shippingSpeed');

  const [updateShippingSpeedMutation] = useMutation(SHIPPING_SPEED_UPDATE_MUTATION, {
    onCompleted: ({ updateShippingSpeed }) => {
      if (updateShippingSpeed?.responseInfo?.success) {
        logger.debug('updateShippingSpeedMutation RESULT', updateShippingSpeed.shippingSpeed);
        updateCheckoutPageState(updateShippingSpeed);
        $window.digitalData?.merge('cart', {
          origin: 'checkout',
          data: updateShippingSpeed,
          action: 'shipping_speed_update',
        });
        // trigger custom event with updated cart data for crs to capture
        const shippingSpeedDoneEvent = new CustomEvent('shippingSpeed:update:done', { detail: updateShippingSpeed.cartInfo });
        window.dispatchEvent(shippingSpeedDoneEvent);
      }
      setIsUpdating(false);
    },
    onError: (err, { variables }) => {
      logger.error(`ERR: updateShippingSpeedMutation: ${JSON.stringify(err)}`);
      if (variables.fromPhoenix) {
        window.dispatchEvent(
          new CustomEvent('shippingSpeed:retryUpdate', { detail: { variables } }),
        );
      } else {
        fetchShippingSpeed();
      }
    },
  });
  const getSkuList = (bagItems) => {
    const cartItemIds = {};
    bagItems?.items.forEach((item, index) => {
      cartItemIds[index + 1] = item?.item?.productContent?.shortSku;
    });
    return cartItemIds;
  };
  const getCartPayPalPayment = (payment) => ({
    payPalToken: payment?.payPalToken,
    payPalPayerId: payment?.payPalPayerId,
    paypalTransactionId: payment?.paypalTransactionId,
    paypalAuthorizationId: payment?.paypalAuthorizationId,
    payPalInvoiceNumber: payment?.payPalInvoiceNumber,
    paymentCode: payment?.paymentCode,
    paymentType: payment?.paymentType,
  });

  const handleChangeShippingSpeed = useCallback((shippingSpeed, fromPhoenix = false) => {
    setIsUpdating(true);
    // run shipping speed mutation
    const variables = {
      shipModeId: shippingSpeed.shipModeId,
      fromPhoenix,
      skus: Object.values(getSkuList(checkoutPageState.bagItems)),
      cartState: {
        isShippingAddressComplete: checkoutPageState.cartState.isShippingAddressComplete,
        isBillingAddressComplete: checkoutPageState.cartState.isBillingAddressComplete,
        isShippingMethodSet: checkoutPageState.cartState.isShippingMethodSet,
        isPaymentComplete: checkoutPageState.cartState.isPaymentComplete,
      },
      cartPayment: {
        paypalPayment: getCartPayPalPayment(checkoutPageState?.cartPayment?.paypalPayment),
      },
      availableShippingMethods: checkoutPageState.shippingSpeed.map((item) => {
        let { availability = null } = item.shipModeId === shippingSpeed?.shipModeId
          ? shippingSpeed : item;

        if (availability) {
          availability = {
            cartID: availability.cartID,
            courierEligible: availability.courierEligible,
            storeNo: availability.storeNo,
            shipDate: availability.shipDate,
            orderByTime: availability.orderByTime,
            timeZone: availability.timeZone,
            reason: availability.reason,
            hasOption: availability.hasOption,
            postalCode: availability.postalCode,
            isValid: availability.isValid,
            isSelected: availability.isSelected,
            isEnabled: availability.isEnabled,
            isDisabled: availability.isDisabled,
            shouldBeSelected: availability.shouldBeSelected,
            eligibilityConfirmed: availability.eligibilityConfirmed,
            ineligibilityConfirmed: availability.ineligibilityConfirmed,
            usingExpressLanePayment: availability.usingExpressLanePayment,
            displayChangeLink: availability.displayChangeLink,
            sddAvailabilityMessage: availability.sddAvailabilityMessage,
            geolocation: availability.geolocation && {
              country: availability.geolocation.country,
              countryAbbr: availability.geolocation.countryAbbr,
              latlng: availability.geolocation.latlng,
              postalCode: availability.geolocation.postalCode,
              postalTown: availability.geolocation.postalTown,
              state: availability.geolocation.state,
              stateAbbr: availability.geolocation.stateAbbr,
            },
          };
        }

        return ({
          amount: item.amount,
          amountFmt: item.amountFmt,
          description: item.description,
          estimatedDeliveryDate: item.estimatedDeliveryDate,
          estimatedDeliveryDateFmt: item.estimatedDeliveryDateFmt,
          externalId: item.externalId,
          isSelected: item.isSelected,
          isEnabled: item.isEnabled,
          shipModeId: item.shipModeId,
          shipModeType: item.shipModeType,
          availability,
        });
      }),
    };
    // TODO: check if we shoud look at 'B' as well
    if (shippingSpeed.addressType !== 'SB') {
      variables.shippingAddress = {
        addressType: shippingSpeed.addressType,
        country: shippingSpeed.country,
        state: shippingSpeed.state,
        addressId: checkoutPageState.shippingAddress.addressId,
        firstName: checkoutPageState.shippingAddress.firstName,
        lastName: checkoutPageState.shippingAddress.lastName,
        collectionPointID: checkoutPageState.shippingAddress.collectionPointID,
        pickUpStoreId: checkoutPageState.shippingAddress.pickUpStoreId,
        postalCode: checkoutPageState.shippingAddress.postalCode,
        province: checkoutPageState.shippingAddress.province,
        // we may need to revisit anc check do we need to pass city and postalcode
      };
    }
    // NOTE: I think we should pass the shipping address up each item
    // so this may not be needed either, but we will keep it here for now, since things
    // appear to be working
    if (shippingSpeed.shipModeType === 'SameDayDelivery') {
      variables.shippingAddress = {
        // TODO: maybe we need to use the shipping address info
        addressType: shippingSpeed.addressType,
        country: shippingSpeed.country,
        state: shippingSpeed.state,
        addressId: checkoutPageState.shippingAddress.addressId,
        firstName: checkoutPageState.shippingAddress.firstName,
        lastName: checkoutPageState.shippingAddress.lastName,
        city: checkoutPageState.shippingAddress.city,
        postalCode: checkoutPageState.shippingAddress.postalCode,
        sameDayStoreId: checkoutPageState.shippingAddress.sameDayStoreId,
        address1: checkoutPageState.shippingAddress.address1,
        address2: checkoutPageState.shippingAddress.address2,
        province: checkoutPageState.shippingAddress.province,
        collectionPointID: checkoutPageState.shippingAddress.collectionPointID,
        pickUpStoreId: checkoutPageState.shippingAddress.pickUpStoreId,
      };
    }

    variables.shipMethod = {
      shipModeType: checkoutPageState?.shipMethod?.shipModeType,
      shipModeId: checkoutPageState?.shipMethod?.shipModeId,
    };

    return updateShippingSpeedMutation({
      variables,
    });
  }, [updateShippingSpeedMutation, checkoutPageState.shippingSpeed]);

  useEffect(() => {
    function handleRetryUpdate(event) {
      event.stopPropagation();
      if (!event?.detail?.fromPhoenix) {
        return;
      }
      updateShippingSpeedMutation({
        variables: {
          ...event?.detail?.variables,
          fromPhoenix: false,
        },
      });
    }

    window.addEventListener('shippingSpeed:retryUpdate', handleRetryUpdate);

    function handleShippingSpeedChange(event) {
      const { shipModeId, fromPhoenix = false } = event.detail;
      const shippingSpeed = checkoutPageState.shippingSpeed
        .find((candidate) => candidate.shipModeId === shipModeId);
      if (shippingSpeed) {
        handleChangeShippingSpeed(shippingSpeed, fromPhoenix);
      }
    }

    window.addEventListener('shippingSpeed:change', handleShippingSpeedChange);

    return function removeListener() {
      window.removeEventListener('shippingSpeed:change', handleShippingSpeedChange);
      window.removeEventListener('shippingSpeed:retryUpdate', handleRetryUpdate);
    };
  }, [
    checkoutPageState,
    fetchShippingSpeed,
    handleChangeShippingSpeed,
  ]);

  if (loading) {
    return <Loader classList="loader" />;
  }

  if (error && !checkoutPageState) {
    return (
      <div className="shipping-speed">
        <h2>Shipping Speed</h2>
        {ErrorMessage}
      </div>
    );
  }

  return (
    <ShippingSpeedOptions
      shippingSpeedOptions={checkoutPageState.shippingSpeed}
      textFor={checkoutPageState.textFor}
      onChangeShippingSpeed={handleChangeShippingSpeed}
      isUpdating={isUpdating}
    />
  );
}

ShippingSpeed.defaultProps = {
  shippingSpeedDetails: undefined,
};

ShippingSpeed.propTypes = {
};
