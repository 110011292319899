import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
} from '@apollo/client';
import { Button, ErrorMessage } from 'anf-core-react';
import useLog from '../../useLog/useLog';
import PageHeader from '../PageHeader/PageHeader';
import BagList from '../../Common/BagList/BagList';
import BagContext from '../../../context/BagContext';
import { SAVEFORLATER_DATA_QUERY } from '../../../gql/sfl.gql';
import Loader from '../../Common/Loader/Loader';
import {
  ERROR_MESSAGE as errorMessage,
  LOADING_MESSAGE as loadingMessage,
} from '../../Common/Messages/Messages';
import Tmnt from '../../Tmnt/Tmnt';

export default function SaveForLater({
  moveToBag,
  deleteFromSFL,
  hasPageHeader,
  isLoggedIn,
  viewType,
}) {
  const {
    savedForLater, setSavedForLater,
  } = useContext(BagContext);
  const LIST_THREE = 3;
  const LIST_SIX = 6;
  const [itemsToShow, setItemsToShow] = useState(LIST_THREE);
  const logger = useLog('saveForLater.root');
  const {
    loading, error, data: queryData, refetch,
  } = useQuery(SAVEFORLATER_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      logger.debug('SAVEFORLATER_DATA_QUERY RESULT', res);
      setSavedForLater(res);
    },
    onError: (err) => {
      logger.error(`ERR: SAVEFORLATER_DATA_QUERY: ${JSON.stringify(err)}`);
    },
  });

  useEffect(() => {
    window.addEventListener('shoppingBag:submitBagMutation:done', (event) => {
      event.stopPropagation();
      refetch().then((res) => {
        logger.debug('SAVEFORLATER_DATA_QUERY REFETCH RESULT', res);
        setSavedForLater((previousState) => ({
          ...previousState,
          savedForLaterItems: res?.data?.savedForLaterItems,
        }));
      });
    });
  });

  if (loading) {
    return <Loader classList="loader" />;
  }
  if (error) return errorMessage;
  if (!queryData) return loadingMessage;

  const sflList = savedForLater?.savedForLaterItems?.bagItems?.items;
  const hasSFLItems = sflList && Object.keys(sflList).length !== 0;
  const sflItemCount = hasSFLItems ? Object.keys(sflList).length : '0';
  const itemString = sflItemCount === 1 ? savedForLater?.textFor?.itemTMNTValue?.value
    : savedForLater?.textFor?.itemsTMNTValue?.value;
  const sflHeadline = savedForLater?.textFor?.sflTMNTValue?.value || 'Saved for Later';
  const viewMoreButton = savedForLater?.textFor?.viewMoreButton?.value || 'View More';
  const viewAllButton = savedForLater?.textFor?.viewAllButton?.value || 'View All';
  const viewLessButton = savedForLater?.textFor?.viewLessButton?.value || 'View Less';
  let sflDesc;
  if (isLoggedIn) {
    if (hasSFLItems) {
      sflDesc = savedForLater?.textFor?.sflDescriptionLoggedInUser;
    } else {
      sflDesc = savedForLater?.textFor?.emptySFLText;
    }
  } else if (hasSFLItems) {
    sflDesc = savedForLater?.textFor?.sflDescriptionGuest;
  } else {
    sflDesc = savedForLater?.textFor?.emptySFLGuestText;
  }

  const isShowAll = () => itemsToShow >= (LIST_THREE * 2);

  const viewMoreOrAll = () => {
    setItemsToShow((prev) => (isShowAll()
      ? sflList?.length : prev + LIST_THREE));
  };

  return (
    <div className={`save-for-later ${viewType === 'tabbed' ? 'sfl-tabbed' : 'sfl-stacked'}`} data-testid="sfl-component">
      {hasPageHeader && <PageHeader value={`${sflHeadline} (${sflItemCount} ${itemString})`} tmntKey="GLB_BAG_HEADER" classes="shopping-bag-header" /> }
      { sflDesc && (
        <p className="sfl-description" data-testid="description-field">
          <Tmnt tmnt={sflDesc} isHtml />
        </p>
      )}
      {
        !savedForLater?.savedForLaterItems?.responseInfo?.success
        && savedForLater?.savedForLaterItems?.responseInfo?.statusCode !== '401'
        && (
          <div className="error-section" data-testid="sfl-generic-error">
            {savedForLater?.savedForLaterItems?.responseInfo?.statusMessages?.map(
              (err) => err?.message && <ErrorMessage key={err}>{err?.message}</ErrorMessage>,
            )}
          </div>
        )
      }
      <div className="saved-for-later-product-list">
        {(sflItemCount > 0) && (
          <BagList
            bagItems={sflList?.slice(0, itemsToShow)}
            tmntData={savedForLater?.textFor}
            variant="sfl"
            moveToBag={moveToBag}
            deleteFromSFL={deleteFromSFL}
          />
        )}
      </div>
      {sflList?.length > itemsToShow && (
      <div className="load-more">
        <Button variant="tertiary-light" classList="view-more-items" onClick={viewMoreOrAll}>
          {itemsToShow >= LIST_SIX ? viewAllButton : viewMoreButton}
        </Button>
      </div>
      )}
      {sflList?.length !== LIST_THREE && sflList?.length === itemsToShow && (
      <div className="load-less">
        <Button variant="tertiary-light" classList="view-less-items" onClick={() => { setItemsToShow(LIST_THREE); }}>
          {viewLessButton}
        </Button>
      </div>
      )}
    </div>
  );
}

SaveForLater.defaultProps = {
  moveToBag: undefined,
  deleteFromSFL: undefined,
  hasPageHeader: true,
  isLoggedIn: true,
  viewType: 'stacked',
};

SaveForLater.propTypes = {
  moveToBag: PropTypes.func,
  deleteFromSFL: PropTypes.func,
  hasPageHeader: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  viewType: PropTypes.string,
};
