/*

Marketing Preferences Component
Will be used to display the checkboxes of email subscriptions
under the contact information of the user who placed the order.
This component will be used in the Checkout Page.

*/

import React, {
  useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'anf-core-react';
import { useCookies } from 'react-cookie';
import SwitchTestContext from '../../../context/SwitchTestContext';

export default function MarketingPreferences({ marketingPreferencesData }) {
  const marketingPrefBrands = marketingPreferencesData?.brands;
  const [selectedBrands, setSelectedBrands] = useState(
    () => marketingPrefBrands?.map((brand) => brand.selected) ?? [],
  );

  const [, setCookie] = useCookies(['subscribe']);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasActionBasedSubscription = digitalData && digitalData['chk-use-subscribe-actions'];

  const handleCheckboxChange = (index) => {
    const updatedBrands = [...selectedBrands];
    updatedBrands[index] = !updatedBrands[index];
    setSelectedBrands(updatedBrands);
  };

  useEffect(() => {
    const cookieValue = {};
    if (hasActionBasedSubscription) {
      marketingPrefBrands?.forEach((brand, index) => {
        if (selectedBrands[index]) {
          cookieValue[brand.id] = 'Y';
          // Allow N only if user modify/preference
        } else if (!selectedBrands[index] && selectedBrands[index] !== brand.selected) {
          cookieValue[brand.id] = 'N';
        }
      });
    } else {
      marketingPrefBrands?.forEach((brand, index) => {
        cookieValue[brand.id] = selectedBrands[index] ? 'Y' : 'N';
      });
    }

    const cookieValueString = JSON.stringify(cookieValue);

    setCookie('subscribe', cookieValueString, { path: '/' });
  }, [hasActionBasedSubscription, marketingPrefBrands, selectedBrands, setCookie]);

  if (marketingPreferencesData?.hideMarketingPreferences) {
    return null;
  }

  return (
    <div className="marketing-preferences-mfe scope-1892">
      <h3 data-prop={marketingPreferencesData?.headingLabel?.key} className="marketing-preferences-heading">
        {marketingPreferencesData?.headingLabel?.value}
      </h3>
      <div className="marketing-preferences">
        <div className="marketing-preferences-checkboxes">
          {marketingPrefBrands?.map((brand, index) => {
            const isChecked = selectedBrands[index];

            return (
              <Checkbox
                key={brand?.id}
                id={brand?.id}
                name="subscribeToEmails"
                value={isChecked?.toString()}
                description={brand?.label?.value}
                onChange={() => handleCheckboxChange(index)}
                isChecked={isChecked}
              />
            );
          })}
        </div>

      </div>
    </div>
  );
}

MarketingPreferences.propTypes = {
  marketingPreferencesData: PropTypes.shape({
    headingLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    brands: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
        }),
        selected: PropTypes.bool,
      }),
    ),
    hideMarketingPreferences: PropTypes.bool,
  }),
};

MarketingPreferences.defaultProps = {
  marketingPreferencesData: {
    headingLabel: {
      key: '',
      value: 'Get Our Emails',
    },
    brands: [
      {
        id: 'anf',
        label: {
          key: '',
          value: 'Abercrombie & Fitch',
        },
        selected: false,
      },
      {
        id: 'kids',
        label: {
          key: '',
          value: 'abercrombie kids',
        },
        selected: false,
      },
    ],
    hideMarketingPreferences: false,
  },
};

MarketingPreferences.propTypes = {
  marketingPreferencesData: PropTypes.shape({
    headingLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    brands: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    })),
    hideMarketingPreferences: PropTypes.bool,
  }),
};

MarketingPreferences.defaultProps = {
  marketingPreferencesData: {
    headingLabel: {
      key: '',
      value: 'Get Our Emails',
    },
    brands: [
      {
        id: 'anf',
        label: {
          key: '',
          value: 'Abercrombie & Fitch',
        },
        selected: false,
      },
      {
        id: 'kids',
        label: {
          key: '',
          value: 'abercrombie kids',
        },
        selected: false,
      },
    ],
    hideMarketingPreferences: false,
  },
};
