import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import SavedPaymentCard from './SavedPaymentCard';

export default function SavedPaymentsModal({
  isOpen, onClose, savedPayments, tmntData, onSelect,
}) {
  const modalHeading = tmntData?.savedPayments;

  return (
    <Modal
      id="saved-payments-modal"
      isOpen={isOpen}
      onClose={onClose}
      heading={(
        <h2>
          <Tmnt tmnt={modalHeading} />
        </h2>
      )}
      closeButtonLabel="Close"
    >
      <div>
        {savedPayments.map((payment) => (
          <SavedPaymentCard
            key={payment?.savedPaymentId}
            paymentId={payment?.savedPaymentId}
            iconCode={payment?.iconCode}
            cardNumber={payment?.maskedCardNumber}
            expiryMonth={payment?.cardExpiryMonth}
            expiryYear={payment?.cardExpiryYear}
            expTmnt={tmntData?.exp}
            expiresTmnt={tmntData?.expires}
            buttonLabelTmnt={tmntData?.useThisPayment}
            onSelect={onSelect}
          />
        ))}
      </div>
    </Modal>
  );
}

SavedPaymentsModal.defaultProps = {
  isOpen: false,
  savedPayments: [],
  tmntData: {},
};

SavedPaymentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  savedPayments: PropTypes.arrayOf(PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  })),
  tmntData: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func.isRequired,
};
