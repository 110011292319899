import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import CreditCardFields from './CreditCardFields';
import KlarnaFields from './KlarnaFields';

export default function PaymentFields({
  selectedPayment,
  paymentConfig,
  tmntData,
  savedPaymentDetails,
  setSelectedPayment,
  onChange,
  onClear,
}) {
  return (
    <>
      <p className="payment-type-description">
        {selectedPayment?.description && (
          <>
            <Tmnt tmnt={selectedPayment?.description} />
            <br />
          </>
        )}
        {selectedPayment?.message && (
          <>
            <Tmnt tmnt={selectedPayment?.message} />
            <Button
              value="saved-payments"
              variant="borderless"
              classList="learn-more-button ds-override"
              labelText={tmntData?.payOverTimeLearnMore?.value}
              onClick={() => {}}
            >
              <Tmnt tmnt={tmntData?.payOverTimeLearnMore} />
            </Button>
          </>
        )}
      </p>
      {selectedPayment?.type === 'credit card' && (
        <CreditCardFields
          creditCardConfig={paymentConfig?.creditCard}
          payment={selectedPayment}
          tmntData={tmntData}
          savedPaymentDetails={savedPaymentDetails}
          setSelectedPayment={setSelectedPayment}
          onChange={onChange}
          onClear={onClear}
        />
      )}
      {selectedPayment?.type === 'klarna' && (
        <KlarnaFields payment={selectedPayment} />
      )}
      {selectedPayment?.type !== 'credit card'
      && selectedPayment?.type !== 'klarna'
      && (
        <input
          name="payment.paymentCode"
          value={selectedPayment?.id}
          type="hidden"
          data-testid="payment-code"
        />
      )}
    </>
  );
}

PaymentFields.defaultProps = {
  savedPaymentDetails: null,
  tmntData: {},
  setSelectedPayment: () => {},
  onChange: () => {},
  onClear: () => {},
};

PaymentFields.propTypes = {
  selectedPayment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }).isRequired,
  paymentConfig: PropTypes.instanceOf(Object).isRequired,
  setSelectedPayment: PropTypes.func,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
