import React, {
  useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  useMutation,
} from '@apollo/client';
import {
  Accordion, InputField, Button, ErrorMessage, SingleEntryForm, DiscountCard, IconBlock, Icon,
} from 'anf-core-react';
import useLog from '../../useLog/useLog';
import { PROMOTION_UPDATE_MUTATION, PROMOTION_DELETE_MUTATION } from '../../../gql/promotion.gql';
import trackAction from '../../../tools/analytics';
import Loader from '../Loader/Loader';
import $window from '../../../tools/window';

export default function Promotions({
  accordionVariant,
  tmntData,
  // Previously this component relied on the `bagContext` for bag-related data.
  // We have  renamed it to `purchaseContext` and pass it as a prop.
  // so that it can be used both in cart and checkout pages
  purchaseContext,
  showDiscount,
  setPurchaseContext,
  setShowDiscount,
  showNotification,
  setShowNotification,
  className,
  page,
}) {
  const appliedTMNTValue = tmntData?.appliedButton?.value;
  const removeTMNTValue = tmntData?.removeButton?.value;
  const [promo, setPromo] = useState('');
  const [showError, setShowError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const logger = useLog('shoppingBag.promotions');

  const hasItemsInBag = purchaseContext?.orderTotals?.totalItemsInBag;
  const cartBrands = Array.from(new Set(purchaseContext?.bagItems?.items?.map((item) => item?.item?.productContent.brand))).join(',');
  const kicIds = Array.from(new Set(purchaseContext?.bagItems?.items?.map((item) => item?.item?.productContent.kicId))).join(',');

  const [updatePromoMutation] = useMutation(PROMOTION_UPDATE_MUTATION, {
    onCompleted: (updatePromo) => {
      logger.debug('THE PROMOTION_UPDATE_MUTATION RESULT', updatePromo.applyPromo);
      if (updatePromo.applyPromo?.responseInfo?.success) {
        setPurchaseContext((previousState) => {
          const newState = {
            ...previousState,
            orderTotals: updatePromo?.applyPromo?.orderTotals,
            promoInfo: updatePromo?.applyPromo?.promoInfo,
            bagItems: updatePromo?.applyPromo?.bagItems,
            repudiationData: updatePromo?.applyPromo?.repudiationData,
            freeShippingProgressInfo: updatePromo?.applyPromo?.freeShippingProgressInfo,
            giftCards: updatePromo?.applyPromo?.giftCards,
          };
          if (page === 'checkout') {
            newState.charity = updatePromo?.applyPromo?.charity;
            newState.klarnaState = updatePromo?.applyPromo?.klarnaState;
          }

          // merge the bag or checkout response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: page,
            data: newState,
            action: 'promo_add',
          });

          return newState;
        });

        setShowDiscount(true);
        setShowError(false);
        setShowNotification(true);
        setIsLoading(false);
        trackAction('checkout_promo_add_click', {
          data_text: 'promo success',
          data_value: promo,
          data_action: 'successful',
          message: '',
          ...updatePromo?.applyPromo?.orderTotals,
          cartBrands,
          kicIds,
        });
        setPromo('');
      } else {
        // handle Error
        setPurchaseContext((previousState) => ({
          ...previousState,
          networkResponse: updatePromo?.applyPromo?.responseInfo,
        }));
        setShowNotification(false);
        setIsLoading(false);
        setShowError(true);
        trackAction('checkout_promo_fail_click', {
          data_text: 'promo fail',
          data_value: promo,
          data_action: 'failed',
          message: '',
          ...updatePromo?.applyPromo?.orderTotals,
          cartBrands,
          kicIds,
        });
      }
    },
    onError: (err) => {
      logger.error(`ERR: PROMOTION_UPDATE_MUTATION: ${JSON.stringify(err)}`);
    },
  });
  const [deletePromoMutation] = useMutation(PROMOTION_DELETE_MUTATION, {
    onCompleted: (deletePromoObj) => {
      if (deletePromoObj?.removePromo?.responseInfo?.success) {
        setPurchaseContext((previousState) => {
          const newState = {
            ...previousState,
            orderTotals: deletePromoObj.removePromo.orderTotals,
            promoInfo: deletePromoObj.removePromo.promoInfo,
            bagItems: deletePromoObj.removePromo.bagItems,
            rewardsAndPromotions: deletePromoObj.removePromo.rewardsAndPromotions,
            repudiationData: deletePromoObj?.removePromo?.repudiationData,
            freeShippingProgressInfo: deletePromoObj?.removePromo?.freeShippingProgressInfo,
            giftCards: deletePromoObj?.removePromo?.giftCards,
          };
          if (page === 'checkout') {
            newState.charity = deletePromoObj?.removePromo?.charity;
            newState.klarnaState = deletePromoObj?.removePromo?.klarnaState;
          }

          // merge the bag or checkout response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: page,
            data: newState,
            action: 'promo_remove',
          });

          return newState;
        });
        setShowError(false);
        logger.log('bag after deletion', purchaseContext.promoInfo);
        setPromo('');
      }
      setIsLoading(false);
    },
    onError: (err) => {
      setIsLoading(false);
      logger.error(`ERR: PROMOTION_DELETE_MUTATION: ${JSON.stringify(err)}`);
    },
  });

  const handleRemovePromo = useCallback(async (event, code) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await deletePromoMutation({
        variables: {
          promotionCode: code,
        },
      });
      trackAction('checkout_promo_remove_click', {
        data_text: 'promo removed',
        data_value: code,
        data_action: 'successful',
        message: '',
        ...purchaseContext?.orderTotals,
        cartBrands,
        kicIds,
      });
    } catch (err) {
      logger.error(`ERR: PROMOTION_DELETE_MUTATION: ${JSON.stringify(err)}`);
    }
  }, [purchaseContext?.orderTotals, cartBrands, deletePromoMutation, kicIds, logger]);

  const handleApplyPromo = useCallback((event) => {
    event.preventDefault();
    if (promo && hasItemsInBag) {
      setIsLoading(true);
      // run applypromo mutation
      updatePromoMutation({
        variables: {
          promotionCode: promo,
        },
      });
    }
  }, [promo, hasItemsInBag, updatePromoMutation]);
  const isPromoRepudiation = purchaseContext?.repudiationData?.repudiationType === 'PROMO';

  const item = purchaseContext?.promoInfo ? purchaseContext?.promoInfo?.map((codes) => (
    <div data-display={showDiscount} key={codes.orderAdjustmentId}>
      {codes.isImplicitPromo ? (
        <DiscountCard
          discountCardActions={{
            appliedIconBlock: <IconBlock icon={<Icon icon="check" />}><p>{appliedTMNTValue}</p></IconBlock>,
            removeLink: <div />,
          }}
          discountCardContent={{
            discountDescription: '',
            discountDetails: <div />,
            discountTitle: codes.shortDesc || '',
          }}
          id={codes.orderAdjustmentId}
        >
          <React.Fragment key=".0" />
        </DiscountCard>
      ) : (
        <DiscountCard
          discountCardActions={{
            appliedIconBlock: <IconBlock icon={<Icon icon="check" />}><p>{appliedTMNTValue}</p></IconBlock>,
            removeLink:
          <Button
            classList="button ds-override remove-promo"
            variant="borderless"
            labelText={removeTMNTValue}
            onClick={(evt) => handleRemovePromo(evt, codes.promotionCode)}
          >
            <span data-property="GLB_REMOVE">
              <span className="screen-reader-text" />
              {removeTMNTValue}
            </span>
          </Button>,
          }}
          discountCardContent={{
            discountDescription: '',
            discountDetails: <div />,
            discountTitle: codes.shortDesc || '',
          }}
          id={codes.orderAdjustmentId}
        >
          <React.Fragment key=".0" />
        </DiscountCard>
      )}
    </div>
  )) : <div data-display="false" />;
  return (
    <div className={`shopping-bag-promotion-box ${className}`} data-property={tmntData?.promotions?.key}>
      <Accordion
        headingLevel={2}
        title={tmntData?.promotions?.value}
        id="promotion"
        variant={accordionVariant}
        onClick={() => {
          trackAction('universal_click', {
            data_text: 'payment rail promo',
            data_action: 'open',
            event_type: 'click',
          });
          setExpanded(!expanded);
        }}
        isExpanded={expanded}
      >
        <Loader variant="hazy" id="promotions" isActive={isloading}>
          <div data-testid="promotioncomp" data-property={tmntData?.promoCode?.key}>
            <SingleEntryForm>
              <InputField
                id="promosaccordion-voxn2n74o5"
                type="text"
                label={tmntData?.promoCode?.value}
                value={promo}
                onChange={(event) => setPromo(event.target.value)}
                maxLength={16}
                name="promocode"
                isInvalid={showError}
                isDisabled={!hasItemsInBag}
                autoComplete=""
              />
              <Button
                classList="applyPromo"
                isFullWidth
                labelText={tmntData?.applyButton?.value}
                value={tmntData?.applyButton?.value}
                variant="secondary"
                onClick={(e) => { handleApplyPromo(e); }}
                isDisabled={!hasItemsInBag}
              >
                {tmntData?.applyButton?.value}
              </Button>
            </SingleEntryForm>
          </div>

          {showError && purchaseContext?.networkResponse.statusMessages?.map((err) => (
            err?.message && <ErrorMessage key={err}>{err.message}</ErrorMessage>
          ))}

          {(((showNotification
              && purchaseContext?.repudiationData?.repudiationType === 'PROMOTION_REPUDIATION') || isPromoRepudiation)
              && purchaseContext?.repudiationData?.errorMessage)
              && (
              <ErrorMessage>
                {purchaseContext?.repudiationData?.errorMessage}
              </ErrorMessage>
              )}
          {item}
        </Loader>
      </Accordion>
    </div>
  );
}

Promotions.defaultProps = {
  accordionVariant: '',
  tmntData: {},
  // `purchaseContext` is the context data passed as a prop.
  purchaseContext: {},
  showDiscount: false,
  showNotification: false,
  setPurchaseContext: undefined,
  setShowDiscount: undefined,
  setShowNotification: undefined,
  className: '',
  page: '',
};

Promotions.propTypes = {
  accordionVariant: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  // `purchaseContext` is the context data passed as a prop.
  purchaseContext: PropTypes.instanceOf(Object),
  showDiscount: PropTypes.bool,
  setPurchaseContext: PropTypes.func,
  setShowDiscount: PropTypes.func,
  showNotification: PropTypes.bool,
  setShowNotification: PropTypes.func,
  className: PropTypes.string,
  page: PropTypes.string,
};
