import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Toggle, ToggleBlock } from 'anf-core-react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import SwitchTestContext from '../../../../context/SwitchTestContext';
import DELIVERY_OPTION_TAB_UPDATE_MUTATION from '../../../../gql/deliveryOptionTab.gql';
import CheckoutPageContext from '../../../../context/CheckoutPageContext';
import useLog from '../../../useLog/useLog';

export default function DeliveryTabs({ textFor }) {
  const logger = useLog('checkout.changeDeliveryType');
  const DELIVERY = 'delivery';
  const PICKUP = 'pickup';
  const [deliveryMethod, setDeliveryMethod] = useState(DELIVERY);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const previousHomeDelivery = useRef();
  const previousPickup = useRef();
  const { digitalData } = useContext(SwitchTestContext);
  const isDeliveryTabOptionEnabled = digitalData && digitalData['ful-delivery-options-tabs'];

  const {
    isUpdating, checkoutPageState, setCheckoutPageState, setIsUpdating,
  } = useContext(CheckoutPageContext);

  useEffect(() => {
    if (checkoutPageState?.shipMethod) {
      setDeliveryMethod(checkoutPageState?.shipMethod?.isHomeDelivery ? DELIVERY : PICKUP);
      setIsTabVisible(checkoutPageState?.shipMethod?.isTabVisible);
    }
  }, [checkoutPageState?.shipMethod]);
  const [changeDeliveryTypeMutation] = useMutation(DELIVERY_OPTION_TAB_UPDATE_MUTATION, {
    onCompleted: (response) => {
      logger.debug('DELIVERY_OPTION_TAB_UPDATE_MUTATION RESULT', response);
      if (response.changeDeliveryType) {
        setCheckoutPageState((currentState) => ({
          ...currentState, ...response.changeDeliveryType,
        }));
      }
      window.dispatchEvent(new CustomEvent('mfe:changeDeliveryType:update:done', { detail: response.changeDeliveryType.cartInfo }));

      setIsUpdating(false);
    },
    onError: (err) => {
      setIsUpdating(false);
      logger.error(`ERR: DELIVERY_OPTION_TAB_UPDATE_MUTATION: ${JSON.stringify(err)}`);
    },
  });
  if (!isDeliveryTabOptionEnabled || !textFor || !isTabVisible) {
    return null;
  }
  const onChange = (id) => {
    if (isUpdating) return;
    const { shippingAddress, shipMethod } = checkoutPageState;
    const shipMethodObj = {
      shipModeId: shipMethod.shipModeId,
      shipModeType: shipMethod.shipModeType,
      shippingAddress: {
        country: shippingAddress.country,
        addressId: shippingAddress.addressId,
        addressType: shippingAddress.addressType,
        address1: shippingAddress.address1,
        address2: shippingAddress.address2,
        city: shippingAddress.city,
        postalCode: shippingAddress.postalCode,
        state: shippingAddress.state,
        province: shippingAddress.province,
        displayType: shippingAddress.displayType,
        district: shippingAddress.district,
        firstName: shippingAddress.firstName,
        isAPOFPO: shippingAddress.isAPOFPO,
        isBillingAllowed: shippingAddress.isBillingAllowed,
        isDefaultBilling: shippingAddress.isDefaultBilling,
        isDefaultShipping: shippingAddress.isDefaultShipping,
        isShippingAllowed: shippingAddress.isShippingAllowed,
        lastName: shippingAddress.lastName,
        legendAddress: shippingAddress.legendAddress,
        legendName: shippingAddress.legendName,
        orgName: shippingAddress.orgName,
        pickUpStoreId: shippingAddress.pickUpStoreId,
        poBox: shippingAddress.poBox,
        sameDayStoreId: shippingAddress.sameDayStoreId,
        subType: shippingAddress.subType,
        title: shippingAddress.title,
        collectionPointID: shippingAddress.collectionPointID,
      },
    };

    if (deliveryMethod === DELIVERY && (shippingAddress.addressType === 'SB' || shippingAddress.addressType === 'S')) {
      previousHomeDelivery.current = shipMethodObj;
    } else if (deliveryMethod === PICKUP && (shippingAddress.addressType === 'P' || shippingAddress.addressType === 'CP')) {
      previousPickup.current = shipMethodObj;
    }
    const variables = {
      deliveryType: id,
      previousHomeDelivery: previousHomeDelivery.current,
      previousPickup: previousPickup.current,
      activeShippingAddress: {
        country: shippingAddress.country,
        addressType: shippingAddress.addressType,
      },
    };
    setIsUpdating(true);

    setDeliveryMethod(id);
    changeDeliveryTypeMutation({
      variables,
    });
  };
  return (
    <Toggle
      border="round"
      classList="toggle-block"
      labelText="Delivery Option"
    >
      <ToggleBlock
        id="delivery"
        name="delivery"
        value="delivery"
        isChecked={deliveryMethod === DELIVERY}
        onChange={() => { onChange(DELIVERY); }}
      >
        { textFor?.homeDelivery?.value}
      </ToggleBlock>
      <ToggleBlock
        id="pickup"
        isChecked={deliveryMethod === PICKUP}
        name="pickup"
        value="pickup"
        onChange={() => {
          onChange(PICKUP);
        }}
      >
        {textFor?.pickupMyOrder?.value}
      </ToggleBlock>
    </Toggle>
  );
}
DeliveryTabs.propTypes = {
  textFor: PropTypes.shape({
    pickupMyOrder: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    homeDelivery: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};

DeliveryTabs.defaultProps = {
  textFor: {
    pickupMyOrder: {
      key: 'DynamicAccessPointLabels',
      value: 'Pickup My Order',
    },
    homeDelivery: {
      key: 'SHIPTYPE_HOME',
      value: 'Home Delivery',
    },
  },
};
