import React, { useContext, useEffect, useState } from 'react';
import GiftCard from './GiftCard/GiftCard';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import SwitchTestContext from '../../../context/SwitchTestContext';
import PaymentHeader from './PaymentHeader';
import PaymentOptionsContainer from './PaymentOptionsContainer';
import { isPageUrlContains } from '../../../tools/pageHelper';

export default function PaymentManager() {
  const { checkoutPageState } = useContext(CheckoutPageContext);
  const { digitalData } = useContext(SwitchTestContext);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) { return null; }
  const isKlarnaOrApmSelected = (
    checkoutPageState?.selectedPaymentType === 'klarna'
    || checkoutPageState?.selectedPaymentType === 'apm'
  );
  const hasPaymentsMfe = digitalData && digitalData['chk-use-payment-section-mfe'];

  return (
    <div className="payment-manager">
      {
        !isPageUrlContains('/ProcessPayPalReturnAndOrderExpress')
        && checkoutPageState?.switches?.hasGiftCardMFE
        && (
          <>
            <PaymentHeader />
            {checkoutPageState?.config?.numberOfGiftCardsAllowed > 0
            && !isKlarnaOrApmSelected
            && (
              <GiftCard />
            )}
          </>
        )
      }
      {
        hasPaymentsMfe
        && checkoutPageState?.paymentOptions?.length > 0
        && <PaymentOptionsContainer newDesign={false} />
      }
    </div>
  );
}
