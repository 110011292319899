import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputField, TooltipWidget, ErrorMessage,
} from 'anf-core-react';

export default function CVVField(props) {
  const {
    cvv, cardBrand, label, index, onChange,
  } = props;
  const [maxLength, setMaxLength] = useState(3);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);

  useEffect(() => {
    if (cardBrand && String(cardBrand).match(/(amex)|(003)/)) {
      setMaxLength(4);
    } else {
      setMaxLength(3);
    }
  }, [cardBrand]);

  const handleChange = (event) => {
    const input = event.target.value;
    // Update state with formatted input
    onChange(input);
    return input;
  };

  const validateChange = (event) => {
    const input = event.target.value;
    setIsInvalid(!(input.length === maxLength));
  };

  return (
    <InputField
      label={label}
      name={`cvv-field-${index}`}
      id={`cvv-field-${index}`}
      className="cvv-field"
      value={cvv}
      onChange={handleChange}
      onBlur={validateChange}
      type="tel"
      maxLength={maxLength}
      isInvalid={isInvalid}
      autoComplete=""
    >
      <TooltipWidget
        buttonLabelText="Story example"
        contentID="story-tooltip"
        contentPosition="top-right"
        isOpen={isToolTipOpen}
        onFocus={() => setIsToolTipOpen(true)}
        onBlur={() => setIsToolTipOpen(false)}
        onClick={() => setIsToolTipOpen(!isToolTipOpen)}
        onKeyDown={() => setIsToolTipOpen(!isToolTipOpen)}
        onKeyUp={() => {}}
      >
        <img src="https://img.abercrombie.com/is/image/anf/cvv_image.png" alt="For Visa, MasterCard, and Discover, the Security Code is located on the back of the card. For American Express, the Security Code is located on the front of the card." width="220" />
      </TooltipWidget>

      <ErrorMessage id="cvv-error-message">
        Please correct Security Code.
      </ErrorMessage>
    </InputField>
  );
}

CVVField.propTypes = {
  label: PropTypes.string.isRequired,
  cvv: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  cardBrand: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
