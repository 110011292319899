import {
  gql,
} from '@apollo/client';

// QUERY: gql query to fetch OrderConfirmationPAge data
const ORDERCONFIRMATIONPAGE_DATA_QUERY = gql`
 query OrderConfirmationPageQuery {
    survey {
      surveyLinkUrl
    }
    userData {
      isLoggedIn
    }
    footer {
      orderId
      phoneNumberByCountry
      phoneNumberByLanguage
    }
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      subTotal
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmountFmt
      charityRoundUpFmt
      charityTotalFmt
      orderId
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
    }
    rewardsAndPromotions {
      couponInfo {
        couponCode
      }
    }
    smsOptIn {
      smsIcon
      isEnabled
      isSelected
      privacyUrl
      textTermUrl
    }
    orderContact {
      email
    }
    promoInfo {
      promoCompId
      promotionCode
      isRewardPromo
    }
    bagItems {
      hasGiftCard
      hasOnlyGiftCard
      hasOnlyEGiftCard
      items {
       item {
         headers {
           badgeStatusMessage {
            key
            value
          }
           headerMessage
           estimatedShipDate
           shouldDisplayAsbadge
           attached
           fullWidth
           textAlign
           variant
         }
         footer {
           bogoMessage
         }
         image {
           altText
           imageSrc
           imageId
           classList
         }
         secondaryContent {
           buttonGroup
         }
         productContent {
           gender
           name
           size
           brand
           shortSku
           longSku
           orderItemId
           productId
           collectionId
           freeGift
           seq
           faceOut
           faceoutImage
           color
           notWrappable
           deliveryDate
           toEmail
           productPrice {
             description
             originalPrice
             discountPrice
             discountText
             variant
             priceFlag
             original
             discount
             originalUSD
             discountUSD
           }
           promotions {
             shortDesc
             promotionType
           }
           kicId
           isFinalSale
           itemShortDesc
           quantity
         }
       }
       giftBox {
         isWrapped
         giftBoxMessage
       }
      }
     }
     shippingSpeed {
      amount
      amountDisplay
      addressType
      country
      description
      estimatedDeliveryDate
      estimatedDeliveryDateDisplay
      externalId
      isSelected
      isValid
      isEnabled
      shipModeId
      shipModeType
      state
      availability {
        cartID
        courierEligible
        storeNo
        shipDate
        orderByTime
        timeZone
        reason
        hasOption
        postalCode
        isValid
        shouldBeSelected
        eligibilityConfirmed
        ineligibilityConfirmed
        usingExpressLanePayment
        displayChangeLink
        geolocation {
          country
          countryAbbr
          latlng
          postalCode
          postalTown
          state
          stateAbbr
        }
      }
    }
    shipMethod {
        shipModeType
        shipModeId
        estimatedDeliveryDateDisplay
        estimatedPreOrderShipDate
        amountDisplay
        description
    }
    shippingAddress {
      collectionPointID
      collectionPointProvider
      address1
      address2
      addressId
      country
      district
      displayType
      city
      firstName
      isBillingAllowed
      isDefaultBilling
      isDefaultShipping
      isShippingAllowed
      lastName
      orgName
      pickUpStoreId
      sameDayStoreId
      state
      title
      postalCode
      poBox
      isAddressVerified
  }
  giftCards {
      isAllowedGiftCards
      appliedGiftCards {
        id
        maskedNumber
        amountFmt
      }
  }   
  orderInfo {
    paymentDetails {
      iconCode
      paymentLabel
      cardExpiryMonth
      cardExpiryYear
      cardType
      maskedCardNumber
      payPalPaymentEmail
      isCreditCard
    }
  }
    switches {
      loadCompleteMFEOrderConfirmationPage: isOn ( switchKey: "HasOrderConfirmationPageMFE")
    }
    eSpots {
      checkoutShippingmessage: get(espotId: "checkout_shippingmessage") {...eSpotContentValues}
      ocnConfirmationPromotion: get(espotId: "ocn_confirmationpromo") {...eSpotContentValues}
    }
    textFor {
      surveyHeading: pair (pairKey: "surveyHeading") {...values},
      surveyDesc: pair (pairKey: "surveyDesc") {...values},
      surveyLinkLabel: pair (pairKey: "surveyLinkLabel") {...values}
      chatHelpTMNTValue: pair (pairKey: "chatHelpText") {...values}
      phoneNumberOrTMNTValue: pair (pairKey: "orText") {...values}
      customerServiceTMNTValue: pair (pairKey: "customerServiceText") {...values}
      orderNumberSymbolTMNTValue: pair (pairKey: "orderNoSymbolText") {...values}

      getTextUpdateTitle: pair (pairKey: "getTextUpdateTitle") {...values},
      getTextUpdateMessage: pair (pairKey: "getTextUpdateMessage") {...values},
      smsOptInSigned: pair (pairKey: "smsOptInSigned") {...values}
      privacyNotice: pair (pairKey: "privacyNotice") {...values}
      smsOptInAnd: pair (pairKey: "smsOptInAnd") {...values}
      textTerms: pair (pairKey: "textTerms") {...values}
      smsOptInDetail: pair (pairKey: "smsOptInDetail") {...values}
      yourOrder: pair (pairKey: "yourOrder") {...values}
      itemsLabel: pair(pairKey: "itemsLabel") {...values}
      returnRefundButton: pair (pairKey: "returnRefundButton") {...values}
      exchangeButton: pair (pairKey: "exchangeButton") {...values}
      shippingHandlingTextTMNTValue: pair (pairKey: "shippingHandlingText") {...values}
      merchandiseTotalTextTMNTValue: pair (pairKey: "merchandiseTotalText") {...values}
      taxesTextTMNTValue: pair (pairKey: "taxesText") {...values}
      shipmentTotalTextTMNTValue: pair (pairKey: "shipmentTotalText") {...values}
      shippingAriaLabelTMNTValue: pair (pairKey: "shippingHandlingAriaLabel") {...values}
      freeShippingAmountTMNTValue: pair (pairKey: "freeShippingAmountText") {...values}
      freeShippingEarnedTMNTValue: pair (pairKey: "freeShippingEarnedText") {...values}
      giftBoxesLabelTMNTValue: pair (pairKey: "giftBoxesLabel") {...values}
      giftCardLabelTMNTValue: pair (pairKey: "giftCardLabel") {...values}
      estimatedTaxLabelTMNTValue: pair (pairKey: "estimatedTaxLabel") {...values}
      charityLabelTMNTValue: pair (pairKey: "charityLabel") {...values}
      subtotalTMNTValue: pair (pairKey: "subtotal") {...values}
      promoDiscountsLabel: pair (pairKey: "promoDiscountsLabel") {...values}
      popinsShippingAndHandling: pair (pairKey: "popinsShippingAndHandling") {...values}
      orderNumberSymbolTMNTValue: pair (pairKey: "orderNoSymbolText") {...values}
      orderPlacedTMNTValue: pair (pairKey: "orderPlaced") {...values}
      orderNumberTMNTValue: pair (pairKey: "orderNumber") {...values}
      confirmationSentToTMNTValue: pair (pairKey: "confirmationSentTo") {...values}
      estimatedDeliveryTMNTValue: pair (pairKey: "estimatedDelivery") {...values}
      reviewYourOrderTMNT: pair (pairKey: "reviewYourOrder") {...values}
      popinsShippingAndHandling: pair (pairKey: "popinsShippingAndHandling") {...values}
      pickupPointShippingAndHandling: pair (pairKey: "pickupPointShippingAndHandling") {...values}
      freeShippingHandlingTMNT: pair (pairKey: "freeShippingHandling") {...values}
      preOrderTMNT: pair (pairKey: "preOrder") {...values}
      inStockItemsOnlyTMNT: pair (pairKey: "inStock") {...values}
      cardNumberEndingTMNT: pair (pairKey: "cardNumberEndingText") {...values}
      expTextTMNT: pair (pairKey: "expText") {...values}
      expiresTextTMNT: pair (pairKey: "expiresText") {...values}
      shippingTextTMNT: pair (pairKey: "shippingText") {...values}
      yourPickupLocationTmnt: pair (pairKey: "checkYourPickUpText") {...values}
      estimatedPickupTmnt: pair (pairKey: "estimatedPickupText") {...values}  
      storeHoursTmnt: pair (pairKey: "storeHoursText") {...values}
      altPickupOcnHeading: pair (pairKey: "altPickupText") {...values}
      altPickupOcnClock: pair (pairKey: "altPickupClockText") {...values}
      altPickupOcnEmail: pair (pairKey: "altPickupEmailText") {...values}
      altPickupOcnID: pair (pairKey: "altPickupIdText") {...values}
    }  
  }
  fragment values on TextPair {
    key
    value
  }
  fragment eSpotContentValues on ESpotContent {
    name
    content
  }
`;

export const EMAIL_MARKETING_SUBSCRIBE = gql`
  mutation subscribe {
    subscribe {
      success
      statusCode
      statusMessages {
        code
        key
        message
      }
    }
  }
`;

export default ORDERCONFIRMATIONPAGE_DATA_QUERY;
