import { gql } from '@apollo/client';

export const popinsModalTextQuery = gql`
query PopinsModalTextQuery {
  textFor {
    cityStateZip: pair(pairKey: "cityStateZip") {...values}
    estimatedPickup: pair(pairKey: "estimatedPickup") {...values}
    findingStoresNear: pair(pairKey: "findingStoresNear") {...values}
    freeStorePickup: pair(pairKey: "freeStorePickup") {...values}
    loadMore: pair(pairKey: "loadMore") {...values}
    locationsNear: pair(pairKey: "locationsNear") {...values}
    locationServicesBlocked: pair (pairKey: "sddLocationServicesBlocked") {...values}
    mapAndHours: pair(pairKey: "mapAndHours") {...values}
    nearMe: pair(pairKey: "nearMe") {...values}
    noStoresFound: pair(pairKey: "noStoresFound") {...values}
    or: pair(pairKey: "or") {...values}
    pickupUnavailable: pair(pairKey: "pickupUnavailable") {...values}
    pleaseTryDifferentLocation: pair(pairKey: "pleaseTryDifferentLocation") {...values}
    popinsLegal: pair(pairKey: "popinsPudosLegal") {...values}
    popinsResultsError: pair(pairKey: "popinsResultsError") {...values}
    searching: pair(pairKey: "searching") {...values}
    selectStore: pair(pairKey: "selectStore") {...values}
    selectedStore: pair(pairKey: "selectedStore") {...values}
    you: pair(pairKey: "you") {...values}
    closeButton: pair(pairKey: "closeButton") {...values}
  }
}

fragment values on TextPair {
    key
    value
  }
`;

export const getPopinsResultsQuery = gql`
  query getPopinsResults($geolocation: GeolocationInput, $bagItems: [BagItemInput], $shippingAddress: ShippingAddressInput) {
    popins {
      searchStores(geolocation: $geolocation, bagItems: $bagItems, shippingAddress: $shippingAddress) {
        physicalStores  {...physicalStore}
        storeLimit
        showAllStores
      }
    }
  }

  fragment physicalStore on PhysicalStore {
    addressLine
    city
    country
    countryName
    eligibleForPickup
    estimatedPickupDateFmt
    inventoryAvailability {...inventoryAvailability}
    latitude
    longitude
    mapAndHoursUrl
    name
    physicalStoreAttribute {...physicalStoreAttribute}
    postalCode
    province
    state
    stateOrProvinceName
    storeNumber
    storeBrand
    storeBrandLogo
    storeMessage
  }

  fragment inventoryAvailability on InventoryAvailability {
    availabilityDateTime
    availabilityDateTimeFmt
    availableQuantity
    inventoryStatus
    sku
    storeNumber
    unitOfMeasure
  }

  fragment physicalStoreAttribute on PhysicalStoreAttribute {
    displayable
    name
    value
  }
`;
