import {
  gql,
} from '@apollo/client';

const KLARNA_SESSION_QUERY = gql`
  query($sessionRequestBody: KlarnaSessionRequestInput) {
    klarnaSession(sessionRequestBody: $sessionRequestBody) {
      paymentOptions {
        clientToken
        container
        instanceId
        paymentMethodCategories
      }
      clientToken
      sessionId
      responseInfo {
        statusCode
        success
        statusMessages {
          code
          key
          message
        }
      }
    }
  }
`;

export default KLARNA_SESSION_QUERY;
