import {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import useLog from '../useLog/useLog';
import usePromise from '../../hooks/usePromise';

export default function useScript(props) {
  const logger = useLog('useScript')(props.id);
  const element = useRef();
  const [state, setState] = useState({
    id: props.id,
    loading: false,
    loaded: false,
    error: null,
    trigger: true,
  });
  const { resolve, reject, promise } = usePromise();

  useEffect(() => {
    promise.catch((error) => {
      logger.debug('caught error', error);
    });
  });

  useEffect(() => {
    const src = `${props.src}${props.hasCacheBusting ? `?v=${Date.now()}` : ''}`;
    if (state.loading) {
      logger.debug('loading');
      return;
    }

    if (state.loaded) {
      logger.debug('loaded');
      return;
    }

    if (!element.current) {
      logger.debug('check if we have the script on the dom');
      element.current = document.querySelector(`script[src="${src}"]`);
      if (element.current) {
        logger.debug('we have the script on the dom');
      }
    }

    if (!element.current) {
      logger.debug('creating script');
      const current = document.createElement('script');
      // NOTE: this useScript field lets us flag which scripts were loaded by useScript
      current.useScript = true;
      current.id = props.id;
      if ('type' in props && !!props.type) {
        current.type = props.type;
      }
      current.async = 'async' in props ? !!props.async : true;
      current.src = src;
      if (typeof props.attrs === 'object' && props.attrs !== null) {
        Object.entries(props.attrs).forEach(([key, value]) => {
          current.setAttribute(key, value);
        });
      }
      element.current = current;
    }

    if (!element.current.onload) {
      logger.debug('attaching onload');
      element.current.onload = () => {
        logger.debug('loaded');
        setState((currentState) => ({
          ...currentState,
          loading: false,
          loaded: true,
        }));
        resolve();
      };
    }

    if (!element.current.onerror) {
      logger.debug('attaching onerror');
      element.current.onerror = (error) => {
        logger.error(error);
        setState((currentState) => ({
          ...currentState,
          loading: false,
          loaded: true,
          error,
        }));
        reject(error);
      };
    }

    if (state.trigger && !state.loaded && !state.loading) {
      logger.debug('trigger');
      if (!element.current.parentNode) {
        logger.debug('attaching to dom');
        document.body.appendChild(element.current);
        setState((currentState) => ({
          ...currentState,
          trigger: false,
          loading: true,
          loaded: false,
        }));
      } else {
        setState((currentState) => ({
          ...currentState,
          trigger: false,
          loading: false,
          loaded: true,
        }));
        resolve();
      }
    }
  }, [logger, props, state.loaded, state.loading, state.trigger, resolve, reject]);

  return [state, element, promise];
}

useScript.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  attrs: PropTypes.shape({
    [String]: PropTypes.string,
  }),
  hasCacheBusting: PropTypes.boolean,
};

useScript.defaultProps = {
  id: '',
  src: '',
  hasCacheBusting: false,
};
