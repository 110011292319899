import {
  gql,
} from '@apollo/client';

export const SAVEFORLATER_DATA_QUERY = gql`
{
  savedForLaterItems {
    bagItems {
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            shouldDisplayAsbadge
            variant
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          productContent {
            listItemId
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            color
            collectionId
            kicId
            itemShortDesc
            quantity
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
          }
        }
      }
    }
    bagId
    responseInfo {
      statusCode
      statusMessages {
        code
        key
        message
      }
      success
    }
  }
  textFor {
    sflTMNTValue: pair (pairKey: "sflTMNT") {...values}
    viewMoreButton: pair (pairKey: "viewMore") {...values}
    sflDescriptionGuest: pair (pairKey: "sflDescriptionGuest") {...values}
    sflDescriptionLoggedInUser: pair (pairKey: "sflDescriptionLoggedInUser") {...values}
    emptySFLText: pair (pairKey: "emptySFL") {...values}
    emptySFLGuestText: pair (pairKey: "emptySFLGuest") {...values}
    sflButton: pair (pairKey: "sflButton") {...values}
    moveToBagButtonValue: pair (pairKey: "moveToBagButton") {...values}
    viewAllButton: pair (pairKey: "viewAll") {...values}
    viewLessButton: pair (pairKey: "viewLess") {...values}
    inBag: pair (pairKey: "inBag") {...values}
    itemTMNTValue: pair (pairKey: "item") {...values}
    itemsTMNTValue: pair (pairKey: "items") {...values}
    clearance: pair (pairKey: "clearance") {...values}
    member: pair (pairKey: "member") {...values}
  }
}
fragment values on TextPair {
  key
  value
}
`;
export const MOVE_TO_SAVEFORLATER_MUTATION = gql`
mutation MoveToSavedForLater($sku: String!, $orderItemId: String!) {
  moveToSavedForLater(sku: $sku, orderItemId: $orderItemId) {
    bagId
    errorMessage {
      key
      value
    }
    responseInfo {
      statusCode
      statusMessages {
        code
        key
        message
      }
      success
    }
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      subTotal
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      estimatedTaxAmountFmt
      charityRoundUpFmt
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      orderId
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
    listItemId
  }
}
`;
export const MOVE_TO_BAG_MUTATION = gql`
mutation MoveToBag($shortSku: String!, $listItemId: String!, $bagId: String!) {
  moveToBag(shortSku: $shortSku, listItemId: $listItemId, bagId: $bagId) {
    bagItems {
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            listItemId
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            notWrappable
            kicId
            isFinalSale
            itemShortDesc
            quantity
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
      hasGiftCard
      hasOnlyGiftCard
    }
    errorMessage {
      key
      value
    }
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      subTotal
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      estimatedTaxAmountFmt
      charityRoundUpFmt
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      orderId
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
    responseInfo {
      statusCode
      statusMessages {
        code
        key
        message
      }
      success
    }
  }
}
`;
export const DELETE_FROM_SFL_MUTATION = gql`
mutation RemoveSavedForLaterItem($listItemId: String!, $bagId: String!) {
  removeSavedForLaterItem(listItemId: $listItemId, bagId: $bagId) {
    responseInfo {
      statusCode
      statusMessages {
        code
        key
        message
      }
      success
    }
    errorMessage {
      key
      value
    }
  }
}`;
