import {
  gql,
} from '@apollo/client';

export const SHOPPINGBAG_DATA_QUERY = gql`
query bag {
  bagItems {
    items {
      item {
        headers {
          badgeStatusMessage {
            key
            value
          }
          headerMessage
          estimatedShipDate
          shouldDisplayAsbadge
          attached
          fullWidth
          textAlign
          variant
        }
        footer {
          bogoMessage
        }
        image {
          altText
          imageSrc
          imageId
          classList
        }
        imageOverlay {
          productUrl
          zoomIn
        }
        secondaryContent {
          buttonGroup
        }
        productContent {
          gender
          name
          size
          brand
          shortSku
          longSku
          orderItemId
          productId
          collectionId
          freeGift
          seq
          faceOut
          faceoutImage
          color
          notWrappable
          productPrice {
            description
            originalPrice
            discountPrice
            discountText
            variant
            priceFlag
            original
            discount
            originalUSD
            discountUSD
          }
          promotions {
            shortDesc
            promotionType
          }
          deliveryDate
          toEmail
          editRemoveButtonVariant
          editRemoveButton
          hasEdit
          hasRemove
          kicId
          isFinalSale
          itemShortDesc
          quantity
        }
        socialProof {
          message
          type
        }
      }
      giftBox {
        isWrapped
        giftBoxMessage
      }
    }
    hasGiftCard
    hasOnlyGiftCard
    responseInfo {
      statusCode
      success
      statusMessages {
        code
        key
        message
      }
    }
  }
  promoInfo {
    promoCompId
    promotionCode
    promotionKey
    longDesc
    isShippingPromo
    isRewardPromo
    isPromoBadging
    isImplicitPromo
    amount
    amountFmt
    orderAdjustmentId
    shortDesc
    promotionType
  }
  checkoutButton {
    checkoutUrl
    variant
  }
  applePayButton {
    orderConfirmationURL
    hasApplePay
    hasApplePayCheckout
    applePaySSLClientAlias
    applePayMerchantCodes
    applePayMerchantIds
  }
  payPalExpressButton {
    hasPayPal
    hasGiftBoxOnPaypalExpress
    paypalExpressUrl
    payPalEnvironment
    hasPaypalFailoverEnabled
    payPalMerchantId
    payPalRedirectUrl
    PayPalInContextScriptSrc
    hasPayPalExpressOnPhoenixCheckout
  }
  orderInStore {
    isSalesAssociateUser
    oisId
    physicalStoreId
    hasEmailOptIn
    sendToRegisterURL
    startNewSessionURL
    redirectOiSUrl
    oisBrand
    skus
    sendToCustomerLabel {
      key
      value
    }
    sendToRegisterLabel {
      key
      value
    }
    startNewSessionLabel {
      key
      value
    }
    orLabel {
      key
      value
    }
    yourEmailLabel {
      key
      value
    }
    emailAddressLabel {
      key
      value
    }
    invalidEmailLabel {
      key
      value
    }
    signUpForPromotionalEmailsLabel {
      key
      value
    }
    termsAndConditionsLabel {
      key
      value
    }
    termsAndConditionsErrorLabel {
      key
      value
    }
    submitLabel {
      key
      value
    }
    registerSuccessLabel {
      key
      value
    }
    customerSuccessLabel {
      key
      value
    }
    thankYouLabel {
      key
      value
    }
    correctHighlightedFieldsLabel {
      key
      value
    }
    transferAPIErrorLabel {
      key
      value
    }
    processingLabel {
      key
      value
    }
    sendToCustomerInsteadLabel {
      key
      value
    }
    sendToRegisterInsteadLabel {
      key
      value
    }
  }
  orderTotals {
    totalItemsInBag
    cartTotalAmount
    cartPayableAmount
    subTotalFmt
    subTotal
    promoAmount
    promoAmountFmt
    shippingHandlingChargeFmt
    totalGiftBoxes
    totalGiftBoxesAmount
    isGiftReceiptChecked
    displayFreeShippingText
    displayGiftReceiptText
    estimatedTaxAmountFmt
    charityRoundUpFmt
    orderId
    includesConsumptionTax
    includesGst
    includesVat
    grandTotal
    grandTotalFmt
    currency
    subTotalUSD
    grandTotalUSD
    cartTotalAmountUSD
  }
  freeShippingProgressInfo {
    isVisible
    amountToFreeShipping
    amountToFreeShippingFmt
    preLabel
    postLabel
    progressValue
    maxProgress
    minProgress
  }
  userData {
    statusCode
    success
    statusMessages {
      code
      key
      message
    }
    hasBagSignIn
    hasLoyaltyEnabled
    isRemembered
    isLoggedIn
    loggedInData {
      registrationDate
      userName
      memberSince
    }
    iconImageUrl
    loyaltyInfo {
      pointBalance
      nextRewardPointsThreshold
      formattedNextRewardValue
      accountStatus
      loyaltyTierInfo {
        loyaltyTier
        actionNeededForNextTier
        formattedActionNeededForNextTier
        spendByDateForNextTier
        formattedLoyaltyTierExpirationDate
      }
      currencyUntilNextRewardValue {
        key
        value
      }
      earnPointsTMNT {
        key
        value
      }
      loyaltyEstimatedPoints
      estimatedPointsTMNT {
        key
        value
      }
    }
    displayIconImage
  }
  rewardsAndPromotions {
    couponInfo {
      couponCode
      associatedPromoKey
      associatedPromoName
      formattedValue
      couponExpiryDate
      couponApplied
      offerHeaderKey
      offerHeader
      promoType
      exclusionsApplyTmntKey
      offerType
      isPromotion
      associatedPoints
      tier
    }
    legalTermsTmntKey
    totalCoupons
  }
  repudiationData {
    errorMessage
    itemsToRemove
    repudiationType
    checkoutUrl
    hasCheckoutButton
    continueInCurrency
    displayRepudiation
    repudiationProducts {
      hasGiftCard
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            isFinalSale
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
    }
  }
  textFor {
    editButton: pair (pairKey: "editButton") {...values}
    removeButton: pair (pairKey: "removeButton") {...values}
    appliedButton: pair (pairKey: "appliedButton") {...values}
    applyButton: pair (pairKey: "applyButton") {...values}
    buyAgainButton: pair (pairKey: "buyAgainButton") {...values}
    returnRefundButton: pair (pairKey: "returnRefundButton") {...values}
    exchangeButton: pair (pairKey: "exchangeButton") {...values}
    itemsLabel: pair (pairKey: "itemsLabel") {...values}
    checkoutBtnTMNTValue: pair (pairKey: "checkoutButtonText") {...values}
    chatHelpTMNTValue: pair (pairKey: "chatHelpText") {...values}
    customerServiceTMNTValue: pair (pairKey: "customerServiceText") {...values}
    orderNumberSymbolTMNTValue: pair (pairKey: "orderNoSymbolText") {...values}
    phoneNumberOrTMNTValue: pair (pairKey: "orText") {...values}
    shippingHandlingTextTMNTValue: pair (pairKey: "shippingHandlingText") {...values}
    merchandiseTotalTextTMNTValue: pair (pairKey: "merchandiseTotalText") {...values}
    totalDiscountTextTMNTValue: pair (pairKey: "totalDiscountText") {...values}
    taxesTextTMNTValue: pair (pairKey: "taxesText") {...values}
    shipmentTotalTextTMNTValue: pair (pairKey: "shipmentTotalText") {...values}
    shippingAriaLabelTMNTValue: pair (pairKey: "shippingHandlingAriaLabel") {...values}
    freeShippingAmountTMNTValue: pair (pairKey: "freeShippingAmountText") {...values}
    freeShippingEarnedTMNTValue: pair (pairKey: "freeShippingEarnedText") {...values}
    paypalButtonTMNTValue: pair (pairKey: "paypalButtonText") {...values}
    applePayButtonTMNTValue: pair (pairKey: "applePayButtonText") {...values}
    clearance: pair (pairKey: "clearance") {...values}
    shopSimilarItemsText: pair (pairKey: "shopSimilarItemsText") {...values}
    errSimilarItemsText: pair (pairKey: "errSimilarItemsText") {...values}
    loadingSimilarItemsText: pair (pairKey: "loadingSimilarItemsText") {...values}
    member: pair (pairKey: "member") {...values}
    rewardsAndOffersTMNT: pair (pairKey: "rewardsAndOffers") {...values}
    loyaltyRedeemedTMNT: pair (pairKey: "loyaltyRedeemed") {...values}
    loyaltyRedeemTMNT: pair (pairKey: "loyaltyRedeem") {...values}
    loyaltyEstimateDetailsTMNT: pair (pairKey: "loyaltyEstimateDetailes") {...values}
    loyaltyUndoTMNT: pair (pairKey: "loyaltyUndo") {...values}
    loyaltyExclusionApplyTMNT: pair (pairKey: "loyaltyExclusionsApply") {...values}
    addGiftBoxTMNTValue: pair (pairKey: "addGiftBoxText") {...values}
    addGiftBoxesTMNTValue: pair (pairKey: "addGiftBoxesText") {...values}
    addOrEditGiftBoxText: pair (pairKey: "addOrEditGiftBoxText") {...values}
    addFreeGiftReceiptTMNTValue: pair (pairKey: "addFreeGiftReceiptText") {...values}
    glbGiftBoxTitleTMNTValue: pair (pairKey: "glbGiftBoxTitleText") {...values}
    glbGiftBoxDetailsExceptionTMNTValue: pair (pairKey: "glbGiftBoxDetailsExceptionText") {...values}
    glbDetailsTMNTValue: pair (pairKey: "glbDetailsText") {...values}
    glbGiftBoxDetailsExplanationTMNTValue: pair (pairKey: "glbGiftBoxDetailsExplanationText") {...values}
    glbAddGiftBoxTMNTValue: pair (pairKey: "glbAddGiftBoxText") {...values}
    giftboxUnavailable: pair (pairKey: "giftboxUnavailable") {...values}
    chkDoneTMNTValue: pair (pairKey: "chkDoneText") {...values}
    glbCancelTMNTValue: pair (pairKey: "glbCancelText") {...values}
    chkGiftMessageTitleTMNTValue: pair (pairKey: "chkGiftMessageTitleText") {...values}
    chkGiftMessageLegendTMNTValue: pair (pairKey: "chkGiftMessageLegendText") {...values}
    signIn: pair (pairKey: "signIn") {...values}
    signOutTMNTValue: pair (pairKey: "signOut") {...values}
    getRewarded: pair (pairKey: "getRewarded") {...values}
    currencyUntilNextReward: pair (pairKey: "currencyUntilNextReward") {...values}
    heyUser: pair (pairKey: "heyUser") {...values}
    dateText: pair (pairKey: "dateText") {...values}
    sessionText: pair (pairKey: "sessionText") {...values}
    loyaltyProgressBarText: pair (pairKey: "loyaltyProgressBarText") {...values}
    loyaltyCurrency: pair (pairKey: "loyaltyCurrency") {...values}
    loyaltyTrackerContentCopyPreText: pair (pairKey: "loyaltyTrackerContentCopyPreText") {...values}
    loyaltyTrackerContentCopyMidText: pair (pairKey: "loyaltyTrackerContentCopyMidText") {...values}
    loyaltyTierActionText: pair (pairKey: "loyaltyTierActionText") {...values}
    loyaltyVipProgressXXOfXX: pair (pairKey: "loyaltyVipProgressXXOfXX") {...values}
    loyaltyTierTrackerHeading: pair (pairKey: "loyaltyTierTrackerHeading") {...values}
    loyaltyTierRetentionHeading: pair (pairKey: "loyaltyTierRetentionHeading") {...values}
    loyaltyTrackerContentCopyPostTet: pair (pairKey: "loyaltyTrackerContentCopyPostTet") {...values}
    loyaltyTierTrackerHeadingVip: pair (pairKey: "loyaltyTierRetentionHeadingVip") {...values}
    loyaltyTierRetentionHeadingVip: pair (pairKey: "loyaltyTierRetentionHeadingVip") {...values}
    loyaltyTrackerContentCopyPostTextVip: pair (pairKey: "loyaltyTrackerContentCopyPostTextVip") {...values}
    loyaltyVIPUntil: pair (pairKey: "loyaltyVIPUntil") {...values}
    loyaltyTierTrackerHeadingPlatinum: pair (pairKey: "loyaltyTierTrackerHeadingPlatinum") {...values}
    loyaltyTierRetentionHeadingPlatinum: pair (pairKey: "loyaltyTierRetentionHeadingPlatinum") {...values}
    loyaltyPlatinumUntilText: pair (pairKey: "loyaltyPlatinumUntilText") {...values}
    loyaltyLogoLabel: pair (pairKey: "loyaltyLogoLabel") {...values}
    loyaltyExp: pair (pairKey: "loyaltyExp") {...values}
    shoppingBagTMNTValue: pair (pairKey: "shoppingBag") {...values}
    shippingAriaLabelTMNTValue: pair (pairKey: "shippingHandlingAriaLabel") {...values}
    shippingHandlingTextTMNTValue: pair (pairKey: "shippingHandlingText") {...values}
    disclosureInfoTMNTValue: pair (pairKey: "disclosureInfo") {...values}
    disclosureTMNTValue: pair (pairKey: "disclosure") {...values}
    removeItemsTMNTValue: pair (pairKey: "removeItems") {...values}
    removeItemTMNTValue: pair (pairKey: "removeItem") {...values}
    continueToCheckoutTMNTValue: pair (pairKey: "continueToCheckout") {...values}
    orTMNTValue: pair (pairKey: "glbOr") {...values}
    giftBoxesLabelTMNTValue: pair (pairKey: "giftBoxesLabel") {...values}
    giftCardLabelTMNTValue: pair (pairKey: "giftCardLabel") {...values}
    estimatedTaxLabelTMNTValue: pair (pairKey: "estimatedTaxLabel") {...values}
    charityLabelTMNTValue: pair (pairKey: "charityLabel") {...values}
    subtotalTMNTValue: pair (pairKey: "subtotal") {...values}
    sendTo: pair (pairKey: "sendTo") {...values}
    sendOn: pair (pairKey: "sendOn") {...values}
    takingYouToCheckout: pair (pairKey: "takingYouToCheckout") {...values}
    inBagTMNTValue: pair (pairKey: "inBag") {...values}
    itemTMNTValue: pair (pairKey: "item") {...values}
    itemsTMNTValue: pair (pairKey: "items") {...values}
    finalSaleTMNTValue: pair (pairKey: "finalSale") {...values}
    emptyBagLoggedOut: pair (pairKey: "emptyBagLoggedOut") {...values}
    emptyBagSignInButton: pair (pairKey: "emptyBagSignInButton") {...values}
    skuText: pair (pairKey: "skuText") {...values}
    soldOutTMNTValue: pair (pairKey: "soldOutRepudiation") {...values}
    orderTotalIncludesConsumpTaxTMNTValue: pair (pairKey: "orderTotalIncludesConsumpTax") {...values}
    orderTotalIncludesGstTMNTValue: pair (pairKey: "orderTotalIncludesGst") {...values}
    orderTotalIncludesVatTMNTValue: pair (pairKey: "orderTotalIncludesVat") {...values}
    subTotalIncludesVatTMNTValue: pair (pairKey: "subTotalIncludesVat") {...values}
    sflButton: pair (pairKey: "sflButton") {...values}
    saveForLaterTMNTValue: pair (pairKey: "sflTMNT") {...values}
    emptyBag: pair (pairKey: "emptyBag") {...values}
    soldoutMovedToSfl: pair (pairKey: "soldoutMovedToSfl") {...values}
    loyaltyCrossBorderWarning: pair (pairKey: "loyaltyCrossBorderWarning") {...values}
    promotions: pair (pairKey: "promotions") {...values}
    promoCode: pair (pairKey: "promoCode") {...values}
    giftReceiptIncluded: pair (pairKey: "giftReceiptIncluded") {...values}
    giftReceipText: pair (pairKey: "giftReceipText") {...values}
    freeShippingHandling: pair (pairKey: "freeShippingHandling") {...values}
  }
  config {
    showDisclosure: bool (name: "showDisclosure")
  }
  switches {
    hasGiftBox: isOn ( switchKey: "HasGiftBox")
    hasCheckoutButtonCartPut: isOn ( switchKey: "HasCheckoutButtonCartPut")
    hasGiftReceipt: isOn ( switchKey: "HasGiftReceipt")
  }
  eSpots {
    cartMemberPricingBelowRewards: get(espotId: "bag_memberpricing_belowrewards") {...eSpotContentValues}
    cartMemberPricingBelowSubtotal: get(espotId: "bag_memberpricing_belowsubtotal") {...eSpotContentValues}
    cartFreeShippingPromo: get(espotId: "bag_freeshipping_belowsubtotal") {...eSpotContentValues}
    cartFix: get(espotId: "bag_fix") {...eSpotContentValues}
    cartTop: get(espotId: "bag_top") {...eSpotContentValues}
    cartToutsBottom: get(espotId: "bag_tout_bottom") {...eSpotContentValues}
  }
  footer {
    phoneNumberByCountry
    phoneNumberByLanguage
  }
  giftBox {
    priceFormatted
  }
  vatDetails {
    showVatDetails
    vatIncludedText
    dutyVatDetailsText
    vatDetailsText {
      key
      value
    }
  }
  page
}
fragment values on TextPair {
  key
  value
}
fragment eSpotContentValues on ESpotContent {
  name
  content
}
`;
export const SUBMIT_BAG_MUTATION = gql`
mutation SubmitBag($isSaveForLaterEnabled: Boolean!) {
  submitBag(isSaveForLaterEnabled: $isSaveForLaterEnabled) {
    bagItems {
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            listItemId
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              original
              discount
              discountPrice
              discountText
              variant
              priceFlag
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            notWrappable
            kicId
            isFinalSale
            itemShortDesc
            quantity
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
      hasGiftCard
      hasOnlyGiftCard
    }
    promoInfo {
      promoCompId
      promotionCode
      promotionKey
      longDesc
      isShippingPromo
      isRewardPromo
      isPromoBadging
      isImplicitPromo
      amount
      amountFmt
      orderAdjustmentId
      shortDesc
      promotionType
    }
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      subTotal
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      estimatedTaxAmountFmt
      charityRoundUpFmt
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      orderId
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
    }
    responseInfo {
      statusCode
      statusMessages {
        code
        key
        message
      }
      success
    }
    rewardsAndPromotions {
      couponInfo {
        couponCode
        associatedPromoKey
        associatedPromoName
        formattedValue
        couponExpiryDate
        couponApplied
        offerHeaderKey
        offerHeader
        promoType
        exclusionsApplyTmntKey
        offerType
        isPromotion
        associatedPoints
        tier
      }
      legalTermsTmntKey
      totalCoupons
    }
    repudiationData {
      errorMessage
      repudiationType
      itemsToRemove
      repudiationProducts {
        hasGiftCard
        items {
          item {
            headers {
              badgeStatusMessage {
                key
                value
              }
              headerMessage
              estimatedShipDate
              shouldDisplayAsbadge
              attached
              fullWidth
              textAlign
              variant
            }
            footer {
              bogoMessage
            }
            image {
              altText
              imageSrc
              imageId
              classList
            }
            imageOverlay {
              productUrl
              zoomIn
            }
            secondaryContent {
              buttonGroup
            }
            productContent {
              listItemId
              gender
              name
              size
              brand
              shortSku
              longSku
              orderItemId
              productId
              collectionId
              freeGift
              seq
              faceOut
              faceoutImage
              color
              productPrice {
                description
                originalPrice
                original
                discount
                discountPrice
                discountText
                variant
                priceFlag
                originalUSD
                discountUSD
              }
              promotions {
                shortDesc
                promotionType
              }
              deliveryDate
              toEmail
              editRemoveButtonVariant
              editRemoveButton
              hasEdit
              hasRemove
              notWrappable
              kicId
              isFinalSale
            }
          }
          giftBox {
            isWrapped
            giftBoxMessage
          }
        }
      }
      checkoutUrl
      hasCheckoutButton
      continueInCurrency
      displayRepudiation
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
  }
}
`;
