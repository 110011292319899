import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Button, InputField, ErrorMessage,
} from 'anf-core-react';
import {
  useMutation,
} from '@apollo/client';
import Tmnt from '../../Tmnt/Tmnt';
import isValidEmail from '../../../tools/isValidEmail';
import {
  OIS_SEND_TO_REGISTER,
  OIS_SEND_TO_CUSTOMER,
} from '../../../gql/orderInStore.gql';
import useLog from '../../useLog/useLog';

export default function OrderInStoreForm({
  oisData,
  isSendToRegister,
  setSubmissionSuccess,
  setIsSendToRegisterFormSubmitted,
}) {
  const [emailValue, setEmailValue] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsInvalid, setTermsInvalid] = useState(false);
  const [optIn, setOptIn] = useState(false);
  const [formFieldsInvalid, setFormFieldsInvalid] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [isPrimaryButtonProcessing, setIsPrimaryButtonProcessing] = useState(false);
  const [isSecondaryButtonProcessing, setIsSecondaryButtonProcessing] = useState(false);
  const logger = useLog('shoppingBag.orderInStoreForm');

  const validateEmail = () => {
    const isValid = isValidEmail(emailValue);
    setEmailInvalid(!isValid);
    return isValid;
  };

  const validateTerms = () => {
    const isValid = termsChecked;
    setTermsInvalid(!isValid);
    return isValid;
  };

  const isFormValid = () => {
    const formValid = (validateEmail(), validateTerms());
    if (!formValid) {
      setFormFieldsInvalid(true);
      setIsPrimaryButtonProcessing(false);
      setIsSecondaryButtonProcessing(false);
    } else {
      setFormFieldsInvalid(false);
    }

    return formValid;
  };

  const setSubmissionResult = (success) => {
    if (success) {
      setSubmissionError(false);
      setSubmissionSuccess(true);
    } else {
      setSubmissionError(true);
      setSubmissionSuccess(false);
    }
    setIsPrimaryButtonProcessing(false);
    setIsSecondaryButtonProcessing(false);
  };

  const [sendToRegister] = useMutation(OIS_SEND_TO_REGISTER, {
    onCompleted: (response) => {
      setSubmissionResult(response?.sendToRegister?.responseInfo?.success);
      logger.debug('THE OIS_SEND_TO_REGISTER RESULT', response);
    },
    onError: (err) => {
      setSubmissionResult(false);
      logger.error(`ERR: OIS_SEND_TO_REGISTER: ${JSON.stringify(err)}`);
    },
  });

  const [sendToCustomer] = useMutation(OIS_SEND_TO_CUSTOMER, {
    onCompleted: (response) => {
      setSubmissionResult(response?.sendToCustomer?.responseInfo?.success);
      logger.debug('THE OIS_SEND_TO_CUSTOMER RESULT', response);
    },
    onError: (err) => {
      setSubmissionResult(false);
      logger.error(`ERR: OIS_SEND_TO_CUSTOMER: ${JSON.stringify(err)}`);
    },
  });

  const formSubmission = (event, isSendToRegisterSubmission) => {
    event.preventDefault();
    if (isFormValid()) {
      // send to register or customer
      return isSendToRegisterSubmission ? sendToRegister({
        variables: {
          email: emailValue,
          brand: oisData.oisBrand,
          oisTrackingId: oisData.oisId,
          skus: oisData.skus,
          store: oisData.physicalStoreId,
          sendToRegisterURL: oisData.sendToRegisterURL,
        },
      }) : sendToCustomer({
        variables: {
          email: emailValue,
          optIn,
        },
      });
    }
    return false;
  };

  return (
    <form onSubmit={formSubmission} name="ois-form" data-testid="ois-form">
      <p className="h2">{oisData?.yourEmailLabel?.value}</p>
      <InputField
        type="text"
        label={oisData?.emailAddressLabel?.value}
        id="ois-email"
        value={emailValue}
        name="ois-email"
        onChange={(e) => setEmailValue(e.target.value)}
        onBlur={validateEmail}
        isInvalid={emailInvalid}
        autoComplete=""
      >
        {emailInvalid && (
          <ErrorMessage id="ois-email-error-message">
            {oisData?.invalidEmailLabel?.value}
          </ErrorMessage>
        )}
      </InputField>
      { oisData?.hasEmailOptIn
        && (
        <Checkbox
          description={(
            <Tmnt tmnt={oisData?.signUpForPromotionalEmailsLabel} isHtml />
          )}
          id="ois_opt_in"
          name="ois_opt_in"
          isChecked={optIn}
          value={(oisData?.signUpForPromotionalEmailsLabel?.value)}
          onChange={() => setOptIn(!optIn)}
        />
        )}
      <Checkbox
        description={(
          <Tmnt tmnt={oisData?.termsAndConditionsLabel} isHtml />
        )}
        id="checkbox-id"
        name="checkbox"
        isChecked={termsChecked}
        isInvalid={termsInvalid}
        onChange={() => {
          const newTermsChecked = !termsChecked;
          setTermsChecked(newTermsChecked);
          setTermsInvalid(!newTermsChecked);
        }}
        value={oisData?.termsAndConditionsLabel?.value}
      />
      { // ask user agree with terms and conditions
        termsInvalid && (
          <ErrorMessage>
            {oisData?.termsAndConditionsErrorLabel?.value}
          </ErrorMessage>
        )
}
      { // ask user to correct highlighted fields
        formFieldsInvalid
        && (
        <ErrorMessage>
          {oisData?.correctHighlightedFieldsLabel?.value ?? ''}
        </ErrorMessage>
        )
      }
      { // display form submission error when API fails
        submissionError
        && (
        <ErrorMessage>
          {oisData?.transferAPIErrorLabel?.value ?? ''}
        </ErrorMessage>
        )
      }
      <Button
        isFullWidth
        type="submit"
        variant="secondary"
        classList="send-to-register-button"
        isProcessing={isPrimaryButtonProcessing}
        isDisabled={isPrimaryButtonProcessing}
        onClick={(e) => {
          setIsPrimaryButtonProcessing(true);
          formSubmission(e, isSendToRegister);
        }}
      >
        {!isPrimaryButtonProcessing && oisData?.submitLabel?.value}
        {isPrimaryButtonProcessing && oisData?.processingLabel?.value}
      </Button>

      { // If we get an API error for sending to register we are offering
        // to send to customer instead
        // and vise versa for sending to customer
        submissionError
        && (
        <Button
          isFullWidth
          type="submit"
          variant="tertiary-light"
          classList="send-to-register-button"
          isProcessing={isSecondaryButtonProcessing}
          isDisabled={isSecondaryButtonProcessing}
          onClick={(e) => {
            setIsSecondaryButtonProcessing(true);
            formSubmission(e, !isSendToRegister);
            setIsSendToRegisterFormSubmitted(!isSendToRegister);
          }}
        >
          {
            isSendToRegister && !isSecondaryButtonProcessing
            && oisData?.sendToCustomerInsteadLabel?.value
          }
          {
            !isSendToRegister && !isSecondaryButtonProcessing
            && oisData?.sendToRegisterInsteadLabel?.value
          }
          {isSecondaryButtonProcessing && oisData?.processingLabel?.value}
        </Button>
        )
      }
    </form>
  );
}

OrderInStoreForm.defaultProps = {
  oisData: {
    oisBrand: '003',
    oisId: '',
    skus: [],
    physicalStoreId: '',
    sendToRegisterURL: '',
    yourEmailLabel: {
      value: 'Your Email',
    },
    emailAddressLabel: {
      value: 'Email Address',
    },
    invalidEmailLabel: {
      value: 'Please enter a valid email address',
    },
    hasEmailOptIn: false,
    signUpForPromotionalEmailsLabel: {
      value: 'Sign up for promotional emails',
    },
    termsAndConditionsLabel: {
      value: 'I agree to the Terms and Conditions',
    },
    termsAndConditionsErrorLabel: {
      value: 'Please agree to the Terms and Conditions',
    },
    correctHighlightedFieldsLabel: {
      value: 'Please correct the highlighted fields',
    },
    transferAPIErrorLabel: {
      value: 'There was an error sending your order to the register. Please try again.',
    },
    submitLabel: {
      value: 'Submit',
    },
    processingLabel: {
      value: 'Processing',
    },
    sendToCustomerInsteadLabel: {
      value: 'Send to Customer Instead',
    },
    sendToRegisterInsteadLabel: {
      value: 'Send to Register Instead',
    },
  },
};

OrderInStoreForm.propTypes = {
  oisData: PropTypes.shape({
    oisBrand: PropTypes.string,
    oisId: PropTypes.string,
    skus: PropTypes.string,
    physicalStoreId: PropTypes.string,
    sendToRegisterURL: PropTypes.string,
    yourEmailLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    emailAddressLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    invalidEmailLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    hasEmailOptIn: PropTypes.bool,
    signUpForPromotionalEmailsLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    termsAndConditionsLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    termsAndConditionsErrorLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    correctHighlightedFieldsLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    transferAPIErrorLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    submitLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    processingLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    sendToCustomerInsteadLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    sendToRegisterInsteadLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  isSendToRegister: PropTypes.bool.isRequired,
  setSubmissionSuccess: PropTypes.func.isRequired,
  setIsSendToRegisterFormSubmitted: PropTypes.func.isRequired,
};
