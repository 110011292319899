import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FieldGroup,
} from 'anf-core-react';
import CardNumber from './CardNumber';
import ExpiryField from './ExpiryField';
import CVVField from './CVVField';

export default function CreditCard({
  index = 0,
  creditCardConfig,
}) {
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [cardBrand, setCardBrand] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCVV] = useState('');
  const [cardCode, setCardCode] = useState('');

  const handleCreditCardNumberChange = (formatCardNumber) => {
    setCreditCardNumber(formatCardNumber?.formattedNumber || '');
    setCardBrand(formatCardNumber?.type || '');
    setCardCode(formatCardNumber?.code || '021');
  };

  return (
    <div className="credit-card-fields">
      <FieldGroup legend="Credit Card Fields">
        <CardNumber
          cardNumber={creditCardNumber}
          cardBrand={cardBrand}
          label={creditCardConfig?.cardNumberLabel?.value || ''}
          index={index}
          onChange={handleCreditCardNumberChange}
          cardTypesList={creditCardConfig?.creditCardTypes}
        />
        <ExpiryField
          expiryDate={expiryDate}
          label={creditCardConfig?.expiryLabel?.value || ''}
          index={index}
          onChange={setExpiryDate}
        />
        <CVVField
          cvv={cvv}
          cardBrand={cardBrand}
          label={creditCardConfig?.securityCodeLabel?.value || ''}
          index={index}
          onChange={setCVV}
        />
      </FieldGroup>
      <input
        name="payment.paymentCode"
        value={cardCode}
        type="hidden"
        data-testid="cc-payment-code"
      />
    </div>
  );
}

CreditCard.propTypes = {
  index: PropTypes.number.isRequired,
  creditCardConfig: PropTypes.shape({
    cardNumberLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    expiryLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    creditCardTypes: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      maxlength: PropTypes.number,
      code: PropTypes.string,
      regex: PropTypes.string,
    })),
  }).isRequired,
};
