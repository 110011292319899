// This helps to process legacy address object that comes
// from form data and returns the addresses as scraped from the form data

const getSelectedShippingKey = (shippingSpeedOptions) => {
  const selectedShippingSpeed = shippingSpeedOptions?.find((shippingSpeedOption) => (
    !!shippingSpeedOption?.isSelected
  ));
  let selectedShippingKey = 'homeDelivery';
  if (selectedShippingSpeed?.shipModeType === 'Popins') {
    selectedShippingKey = 'popins';
  } else if (selectedShippingSpeed?.shipModeType === 'Pudos') {
    selectedShippingKey = 'pudos';
  }
  return selectedShippingKey;
};

const getShippingAddress = (formData, shippingSpeed) => {
  const selectedShippingKey = getSelectedShippingKey(shippingSpeed);
  return formData?.addresses?.[selectedShippingKey];
};

const getBillingAddress = (formData, shippingSpeed) => formData?.addresses?.billing
  || getShippingAddress(formData, shippingSpeed);

const isStateRequiredForCountry = (countryConfig) => {
  if (!countryConfig) {
    return false;
  }

  if (countryConfig.prefecture
    || countryConfig.province
    || countryConfig.region
    || countryConfig.zone
    || countryConfig.state) {
    return true;
  }

  return false;
};

const getCountryConfig = (countryCode, configCountries) => configCountries?.find((
  country,
) => country.id === countryCode);

// function that will return a state if its required for a country
// based on the country config or will return the country code itself
// it will take the country code, state and the configCountries as input
// it is required for CART API
const getStateForCountry = (countryCode, state, configCountries) => {
  const countryConfig = getCountryConfig(countryCode, configCountries);
  if (isStateRequiredForCountry(countryConfig?.addressFieldVisibility)) {
    return state;
  }
  return countryCode;
};

module.exports = {
  getSelectedShippingKey,
  getShippingAddress,
  getBillingAddress,
  isStateRequiredForCountry,
  getCountryConfig,
  getStateForCountry,
};
