import React, { useContext } from 'react';
import { Icon } from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';

function OrderInfo() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const {
    textFor, footer, shipMethod, bagItems, orderContact,
  } = orderConfirmationData;
  return (
    <section className="order-confirmation-card-container" data-testid="order-confirmation-hero">
      <div className="order-confirmation-title">
        <Icon
          icon="checkmark-circle"
          labelText="labelText-Heart"
          size="l"
        />
        <h1>{textFor?.orderPlacedTMNTValue?.value}</h1>
      </div>
      <div className="order-confirmation-card">
        <span data-property={textFor?.orderNumberTMNTValue?.key}>
          {textFor?.orderNumberTMNTValue?.value}
        </span>
        <span>{footer?.orderId}</span>
        <div>
          <span data-property={textFor?.confirmationSentToTMNTValue?.key}>
            {textFor?.confirmationSentToTMNTValue?.value}
          </span>
          <span className="order-confirmation-user-email">{orderContact?.email}</span>
        </div>
        {!bagItems?.hasOnlyEGiftCard
          ? (
            <div className="estimated-delivery-date">
              <span data-property={textFor?.estimatedDeliveryTMNTValue?.value} className="eta">{textFor?.estimatedDeliveryTMNTValue?.value}</span>
              <span>{shipMethod?.estimatedDeliveryDateDisplay}</span>
            </div>
          ) : null}
      </div>
    </section>
  );
}

export default OrderInfo;
