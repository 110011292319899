import React from 'react';
import PropTypes from 'prop-types';
import MarketingSpot from '../../Common/MarketingSpot/MarketingSpot';

export default function ShipmentInfo({
  shipMethod, shippingAddress, textFor, eSpots, orderContact,
}) {
  const {
    firstName,
    lastName,
    city,
    country,
    postalCode,
    state,
    address1,
    address2,
    isDefaultShipping,
    isDefaultBilling,
    isBillingAllowed,
    isShippingAllowed,
    orgName,
    pickUpStoreId,
    poBox,
    addressId,
    isAddressVerified,
    title,
    sameDayStoreId,
  } = shippingAddress;

  const { email } = orderContact;
  const {
    amountDisplay,
    estimatedDeliveryDateDisplay,
    estimatedPreOrderShipDate,
    shipModeId,
    shipModeType,
    description,
  } = shipMethod;
  return (
    <>
      <section className="shipping-info" data-testid="shipment-info-section">
        <dl>
          <dt>
            <p className="name">
              {firstName}
              {' '}
              {lastName}
            </p>
            <p id="shipping-address-1" className="address">{address1}</p>
            {address2 && <p id="shipping-address-2" className="address">{address2}</p>}
            <p className="city-state-postal">
              <span className="city">{`${city}, `}</span>
              <span className="state">{`${state}, `}</span>
              <span className="postal-code">{postalCode}</span>
            </p>
            {sameDayStoreId && <input type="hidden" data-testid="same-day-store-id" value={sameDayStoreId} name="addresses.homeDelivery.sameDayStoreId" />}
            {address1 && <input type="hidden" data-testid="address-line-one" value={address1} name="addresses.homeDelivery.address1" /> }
            {address2 && <input type="hidden" data-testid="address-line-two" value={address2} name="addresses.homeDelivery.address2" />}
            {addressId && <input type="hidden" data-testid="address-id" value={addressId} name="addresses.homeDelivery.addressId" />}
            {city && <input type="hidden" data-testid="city" value={city} name="addresses.homeDelivery.city" />}
            {country && <input type="hidden" data-testid="country" value={country} name="addresses.homeDelivery.country" />}
            {isAddressVerified && <input type="hidden" data-testid="address-verified" value={isAddressVerified} name="addresses.homeDelivery.isAddressVerified" />}
            {email
        && <input type="hidden" value={email} data-testid="payment-email" name="addresses.homeDelivery.email" />}
            {isBillingAllowed && <input type="hidden" value={isBillingAllowed} data-testid="billing-allowed" name="addresses.homeDelivery.isBillingAllowed" />}
            {isDefaultBilling && <input type="hidden" value={isDefaultBilling} data-testid="default-billing" name="addresses.homeDelivery.isDefaultBilling" />}
            {isDefaultShipping && <input type="hidden" value={isDefaultShipping} data-testid="default-shipping" name="addresses.homeDelivery.isDefaultShipping" />}
            {isShippingAllowed && <input type="hidden" value={isShippingAllowed} data-testid="shipping-allowed" name="addresses.homeDelivery.isShippingAllowed" />}
            {firstName && <input type="hidden" value={firstName} data-testid="first-name" name="addresses.homeDelivery.firstName" />}
            {lastName && <input type="hidden" value={lastName} data-testid="last-name" name="addresses.homeDelivery.lastName" />}
            {orgName && <input type="hidden" value={orgName} data-testid="org-name" name="addresses.homeDelivery.orgName" />}
            {pickUpStoreId && <input type="hidden" data-testid="pickup-store-id" value={pickUpStoreId} name="addresses.homeDelivery.pickUpStoreId" />}
            {poBox && <input type="hidden" value={poBox} data-testid="po-box" name="addresses.homeDelivery.poBox" /> }
            {postalCode && <input type="hidden" value={postalCode} data-testid="postal-code" name="addresses.homeDelivery.postalCode" />}
            {state && <input type="hidden" value={state} data-testid="state" name="addresses.homeDelivery.state" />}
            {title && <input type="hidden" value={title} data-testid="title" name="addresses.homeDelivery.title" />}
          </dt>
        </dl>
        <div className="shipping-message" aria-hidden="true">
          <MarketingSpot espotId="checkout_shippingmessage" espotData={eSpots?.checkoutShippingmessage} />
        </div>
      </section>
      <section className="shipping-methods" data-testid="shipping-method">
        <p className="shipping-method-cost">
          {shipModeType !== 'Popins' && (
          <span>
            {`${textFor?.shippingTextTMNT?.value}: `}
          </span>
          )}
          <span className="shipping-method">

            {`${description} - `}
          </span>
          <span className="shipping-cost">{amountDisplay}</span>
        </p>
        <section className="estimated-delivery-date">
          {estimatedDeliveryDateDisplay && (
          <p className="eta" data-edd-source="CMC">
            <span data-property={textFor?.estimatedDeliveryTMNTValue?.key}>
              {textFor?.estimatedDeliveryTMNTValue?.value}
            </span>

            {' '}
            {estimatedDeliveryDateDisplay}
            {estimatedPreOrderShipDate
        && (<span>{textFor?.inStockItemsOnlyTMNT?.value}</span>)}
          </p>
          )}
          {estimatedPreOrderShipDate && (
          <p className="pre-order-items eta" data-edd-source="CMC">
            {`${textFor?.preOrderTMNT?.value}: ${estimatedPreOrderShipDate}`}
          </p>
          ) }
        </section>
        {shipModeId && <input type="hidden" data-testid="ship-mode-id" value={shipModeId} name="shipModeId" />}
      </section>
    </>
  );
}
ShipmentInfo.defaultProps = {
  shipMethod: {},
  shippingAddress: {},
  textFor: {},
  eSpots: {},
  orderContact: {},
};

ShipmentInfo.propTypes = {
  shipMethod: PropTypes.instanceOf(Object),
  shippingAddress: PropTypes.instanceOf(Object),
  textFor: PropTypes.instanceOf(Object),
  eSpots: PropTypes.instanceOf(Object),
  orderContact: PropTypes.instanceOf(Object),
};
