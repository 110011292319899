import {
  React, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import useScript from '../../../Helpers/useScript';

export default function ApplePay({ applePayConfig, orderTotals }) {
  // useScript will load gp.js from pxp on demand
  const [applePayPXPScript] = useScript({
    id: 'apple-pay-pxp-script',
    src: applePayConfig?.pxpScriptURL ?? '',
    attrs: {
      'data-namespace': 'apple-pay-pxp-script',
    },
    hasCacheBusting: true,
  });
  const [applePayReady, setApplePayReady] = useState(false);
  const [currentFormData, setCurrentFormData] = useState(null);

  useEffect(() => {
    if (applePayPXPScript.loaded) {
      // generate a unique clientSystemTransactionId using order id
      const clientSystemTransactionId = `${orderTotals?.orderId}-${Date.now()}`;
      const domainName = window.location.hostname;
      const paymentRequest = applePayConfig?.applePayRequest;
      const useDigitalWalletButton = false;
      const buttonOptions = null; // we are using our own button
      const optionalParams = {
        handleShippingContactSelected: () => {},
        handleShippingMethodSelected: () => {},
      };

      // adding this rule since this function will be a global function
      // loaded from applepay.js hosted by pxp
      // eslint-disable-next-line no-undef
      runApplePay(
        applePayConfig?.mid, // merchant id
        applePayConfig?.sid, // store id
        clientSystemTransactionId, // generated transaction id
        applePayConfig?.publicKeyApi, // public key
        domainName, // domain name
        paymentRequest, // payment request
        useDigitalWalletButton, // we are using our own button
        optionalParams,
        buttonOptions,
        'https://api-integration.pxp-solutions.net/tokenvaultservice/api/v5',
      );
    }
  }, [
    applePayPXPScript?.loaded,
    applePayConfig?.mid,
    applePayConfig?.sid,
    applePayConfig?.publicKeyApi,
    orderTotals?.orderId,
    orderTotals?.grandTotal,
    orderTotals?.currency,
    applePayConfig?.applePayRequest,
  ]);

  useEffect(() => {
    function openApplePaySheet(event) {
      const { formData } = event.detail;
      setCurrentFormData(formData);

      // open Apple Pay sheet using PXP function
      /* eslint-disable-next-line no-undef */
      if (onMerchantButtonClick && typeof onMerchantButtonClick === 'function') {
        /* eslint-disable-next-line no-undef */
        onMerchantButtonClick();
      }
    }
    // add a listener for the Apple Pay ready event
    window.addEventListener('crs:applePay:openSheet', openApplePaySheet);
    return () => {
      window.removeEventListener('crs:applePay:openSheet', openApplePaySheet);
    };
  }, []);

  window.handleApplePayReady = function handleApplePayReady() {
    // PXP will let us know that Apple Pay is ready
    setApplePayReady(true);
  };

  window.handlePaymentError = function handlePaymentError(event) {
    // trigger event to display submit error
    const newEvent = new Event('mfe:checkout:submit');
    newEvent.detail = {
      success: false,
      errorMessage: event.detail.error,
      formData: currentFormData,
    };
    window.dispatchEvent(newEvent);
  };

  window.handlePaymentResult = function handlePaymentResult(event) {
    const paymentResult = event.detail;
    const orderSubmitInput = currentFormData ?? {};

    orderSubmitInput.payment = {
      applePayPayment: {
        appleTransactionId:
          paymentResult?.appleTransactionId ?? paymentResult?.clientSystemTransactionId,
        clientTransactionId: paymentResult?.clientSystemTransactionId,
        cryptogram: paymentResult?.cryptogram ?? '',
        eciIndicator: paymentResult?.eciIndicator ?? '',
      },
      paymentCode: 'applePayPayment',
    };

    // remove some unnecessary fields
    delete orderSubmitInput.orderSubmitLegalTerms;
    delete orderSubmitInput.orderSubmitPaymentMethod;

    // trigger event for submit mutation
    const newEvent = new Event('mfe:checkout:submit');
    newEvent.detail = { success: true, errorMessage: '', formData: currentFormData };

    window.dispatchEvent(newEvent);
  };

  // render an empty div and reflect the state of google pay
  return <div id="mfe-apple-pay" data-ready={applePayReady} data-testid="mfe-apple-pay" />;
}

// props validation
ApplePay.propTypes = {
  applePayConfig: PropTypes.shape({
    mid: PropTypes.string,
    sid: PropTypes.string,
    publicKeyApi: PropTypes.string,
    pxpScriptURL: PropTypes.string,
    applePayRequest: PropTypes.shape({
      supportedNetworks: PropTypes.arrayOf(PropTypes.string),
      countryCode: PropTypes.string,
      currencyCode: PropTypes.string,
      lineItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        amount: PropTypes.string,
      })),
      shippingMethods: PropTypes.arrayOf(PropTypes.shape({
        identifier: PropTypes.string,
        detail: PropTypes.string,
        amount: PropTypes.string,
      })),
      total: PropTypes.shape({
        label: PropTypes.string,
        amount: PropTypes.string,
      }),
    }),
  }).isRequired,
  orderTotals: PropTypes.shape({
    orderId: PropTypes.string,
    grandTotal: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
};
