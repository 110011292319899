import { gql } from '@apollo/client';

export const pudosModalTextQuery = gql`
query PudosModalTextQuery {
  textFor {
    cityStateZip: pair(pairKey: "cityStateZip") {...values}
    findingStoresNear: pair(pairKey: "findingStoresNear") {...values}
    list: pair(pairKey: "list") {...values}
    loadMore: pair(pairKey: "loadMore") {...values}
    locationsNear: pair(pairKey: "locationsNear") {...values}
    locationServicesBlocked: pair (pairKey: "sddLocationServicesBlocked") {...values}
    map: pair(pairKey: "map") {...values}
    mapAndHours: pair(pairKey: "mapAndHours") {...values}
    nearMe: pair(pairKey: "nearMe") {...values}
    noStoresFound: pair(pairKey: "noStoresFound") {...values}
    or: pair(pairKey: "or") {...values}
    pickupUnavailable: pair(pairKey: "pickupUnavailable") {...values}
    pleaseTryDifferentLocation: pair(pairKey: "pleaseTryDifferentLocation") {...values}
    pudosLegal: pair(pairKey: "popinsPudosLegal") {...values}
    searching: pair(pairKey: "searching") {...values}
    selectPickupLocation: pair(pairKey: "selectPickupLocation") {...values}
    selectStore: pair(pairKey: "selectStore") {...values}
    selectedStore: pair(pairKey: "selectedStore") {...values}
    storeHours: pair(pairKey: "storeHours") {...values}
    you: pair(pairKey: "you") {...values}
    closeButton: pair(pairKey: "closeButton") {...values}
  }
  brand
}

fragment values on TextPair {
    key
    value
  }
`;

export const getPudosResultsQuery = gql`
  query getRemoteLocations($locationsControlParameters: LocationsControlParameters, $location: RemoteLocation) {
    pudos {
      remoteLocations(locationsControlParameters: $locationsControlParameters, location: $location) {
        pudosLocations {...pudosLocation}
      }
    }
  }

  fragment pudosLocation on PudosLocation {
    address {...pudosAddress}
    distance
    geoPosition {...geoPosition}
    id
    mapAndHoursUrl
    name
    operatingHours {...operatingHours}
    providerType
    sortingId
  }

  fragment pudosAddress on PudosAddress {
    city
    country
    postalCode
    state
    street
  }

  fragment geoPosition on GeoPosition {
    latitude
    longitude
  }

  fragment operatingHours on PudosOperatingHours {
    dayOfWeek
    closeHours
    openHours
    sortSeq
  }
`;
