import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'anf-core-react';
import CreditCard from '../../CreditCard/CreditCard';
import SelectedSavedPayment from '../SavedPayments/SelectedSavedPayment';
import ModalButtonWithTMNTQuery from '../../../Common/ModalButtonWithTMNTQuery/ModalButtonWithTMNTQuery';

export default function CreditCardFields({
  creditCardConfig,
  payment,
  savedPaymentDetails,
  tmntData,
  onChange,
  onClear,
}) {
  const [isCheckedSave, setIsCheckedSave] = useState(true);

  const handleCheckedSave = useCallback(() => {
    setIsCheckedSave((isCheckedPrev) => !isCheckedPrev);
  }, []);

  return (
    <div className="credit-card-fields-wrap">
      {savedPaymentDetails
        ? (
          <SelectedSavedPayment
            savedPaymentDetails={savedPaymentDetails}
            expTmnt={tmntData?.exp}
            expiresTmnt={tmntData?.expires}
            changeTmt={tmntData?.change}
            provideSecurityCodeTmnt={tmntData?.provideSecurityCode}
            securityCodeTmnt={tmntData?.securityCode}
            clearTmnt={tmntData?.clear}
            onChange={onChange}
            onClear={onClear}
          />
        )
        : (
          <>
            <CreditCard
              index={0}
              creditCardConfig={creditCardConfig}
            />
            {payment?.savable && (
              <>
                <div className="save-card-for-future">
                  <Checkbox
                    description={tmntData?.saveCardForFutureUse?.value}
                    id="save-card-for-future-use-0"
                    isChecked={isCheckedSave}
                    name="creditCard[0].savePayment"
                    value="1"
                    onChange={handleCheckedSave}
                  />
                </div>
                <ModalButtonWithTMNTQuery
                  tmntPairKey="legalPrivacyPolicy"
                  buttonLabelTmnt={tmntData?.privacyPolicy}
                  modalHeadingTmnt={tmntData?.privacyPolicy}
                />
              </>
            )}
          </>
        )}
    </div>
  );
}

CreditCardFields.defaultProps = {
  payment: {},
  savedPaymentDetails: null,
  creditCardConfig: {},
  tmntData: {},
  onChange: () => {},
  onClear: () => {},
};

CreditCardFields.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }),
  creditCardConfig: PropTypes.instanceOf(Object),
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
