import {
  gql,
} from '@apollo/client';

const GIFT_RECEIPT_UPDATE_MUTATION = gql`
  mutation UpdateGiftReceipt {
    updateGiftReceipt {
      orderTotals {
        totalItemsInBag
        cartTotalAmount
        cartPayableAmount
        subTotalFmt
        promoAmount
        promoAmountFmt
        subTotal
        shippingHandlingChargeFmt
        totalGiftBoxes
        totalGiftBoxesAmount
        isGiftReceiptChecked
        displayFreeShippingText
        displayGiftReceiptText
        estimatedTaxAmountFmt
        charityRoundUpFmt
        orderId
        includesConsumptionTax
        includesGst
        includesVat
        grandTotal
        grandTotalFmt
        currency
        subTotalUSD
        grandTotalUSD
        cartTotalAmountUSD
      }
      responseInfo {
        success
      }
    }
  }
`;

export const GIFT_RECEIPT_DELETE_MUTATION = gql`
mutation DeleteGiftReceipt {
  deleteGiftReceipt {
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      promoAmount
      promoAmountFmt
      subTotal
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmountFmt
      charityRoundUpFmt
      orderId
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
    }
    responseInfo {
      success
    }
  }
}
`;

export default GIFT_RECEIPT_UPDATE_MUTATION;
